import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../application/states/hooks";
import { setUfCountState } from "../../application/states/uf-count";
import { usePJApi } from "../../infra/pj-api";

export const useUfCountService = () => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.auth)
  const { findUfCount } = usePJApi()

  const setUfCount = useCallback(() => {
    if (token === '' || !token) {
      return
    }

    findUfCount().then((ufCount: any) => {
      dispatch(setUfCountState({
        ufCount: ufCount
      }))
    })
  }, [dispatch, findUfCount, token])

  return {
    setUfCount
  };
}