import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IExportLayout } from '../../models';
import { IFileExtensionOption } from '../project-download';

interface IExportLayoutSlice {
  modalOpen?: boolean;
  selectedLayout: IExportLayout; // Remover o '?' aqui
  inputValue?: string;
  layouts: IExportLayout[];
  fileExt: IFileExtensionOption;
  action?: 'create' | 'edit';
}

const initialState: IExportLayoutSlice = {
  modalOpen: false,
  layouts: [],
  selectedLayout: {
    id: '',
    companyId: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    createdBy: '',
    name: '',
    layoutColumns: [],
    modelId: '',
  },
  fileExt: {
    label: 'EXCEL - (.xlsx)',
    value: 'xlsx',
  },
};

const exportLayoutSlice = createSlice({
  name: 'export-layout',
  initialState,
  reducers: {
    setExportLayoutActionState: (
      state,
      action: PayloadAction<{
        action: 'create' | 'edit';
      }>
    ) => {
      state.action = action.payload.action;
    },
    setExportLayoutOpenModal(
      state,
      action: PayloadAction<{
        modalOpen: boolean;
      }>
    ) {
      state.modalOpen = action.payload.modalOpen;
    },
    selectExportLayoutState: (state, action: PayloadAction<{
      selectedLayout: IExportLayout
    }>) => {
      state.selectedLayout = action.payload.selectedLayout
    },
    setExportLayoutInputValueState: (
      state,
      action: PayloadAction<{
        inputValue: string;
      }>
    ) => {
      state.inputValue = action.payload.inputValue;
    },
    setExportLayoutsState: (
      state,
      action: PayloadAction<{
        layouts: IExportLayout[];
      }>
    ) => {
      state.layouts = action.payload.layouts;
    },
    setFileExtensionState: (
      state,
      action: PayloadAction<{
        fileExt: IFileExtensionOption;
      }>
    ) => {
      state.fileExt = action.payload.fileExt;
    },
    resetExportLayoutState: (state) => {
      state.modalOpen = initialState.modalOpen;
      state.selectedLayout = initialState.selectedLayout;
      state.inputValue = initialState.inputValue;
      state.layouts = initialState.layouts;
    },
  },
});

export const {
  setExportLayoutOpenModal,
  selectExportLayoutState,
  setExportLayoutInputValueState,
  setExportLayoutsState,
  resetExportLayoutState,
  setExportLayoutActionState,
} = exportLayoutSlice.actions;

export const exportLayoutReducer = exportLayoutSlice.reducer;