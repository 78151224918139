const extraCols = [
    {
        "label": "CNPJ",
        "value": "cnpj"
    },
    {
        "label": "Razão Social",
        "value": "razaosocial"
    },
    {
        "label": "Nome Fantasia",
        "value": "nomefantasia"
    },
    {
        "label": "Situação Cadastral",
        "value": "situacaocadastral"
    },
    {
        'label': 'CNAE',
        'value': 'cnaeprimario'
    },
    {
        'label': 'CNAE Descrição',
        'value': 'cnaeprimariodescricao'
    },
    {
        "label": "Segmento Principal",
        "value": "segmentoprimario"
    },
    {
        "label": "Macroregião",
        "value": "macrorregiao"
    },
    {
        "label": 'Mesorregião',
        "value": 'mesorregiao'
    },
    {
        "label": "Microregião",
        "value": "microrregiao"
    },
    {
        "label": "UF",
        "value": "uf"
    },
    {
        "label": "Cidade",
        "value": "cidade"
    },
    {
        "label": "Endereço Completo",
        "value": "enderecocompleto"
    },
    {
        "label": "Endereço - Tipo",
        "value": "enderecotipo"
    },
    {
        "label": "Endereço - Titulo",
        "value": "enderecotitulo"
    },
    {
        "label": "Endereço - Logradouro",
        "value": "enderecologradouro"
    },
    {
        "label": "Endereço - Número ",
        "value": "endereconumero"
    },
    {
        "label": "Endereço - Complemento ",
        "value": "enderecocomplemento"
    },
    {
        "label": "Bairro ",
        "value": "bairro"
    },
    {
        "label": "CEP",
        "value": "cep"
    },
    {
        "label": "DDD1",
        "value": "ddd1"
    },
    {
        "label": "Telefone 1",
        "value": "telefone1"
    },

    {
        "label": "DDD2",
        "value": "ddd2"
    },
    {
        "label": "Telefone 2",
        "value": "telefone2"
    },
    {
        "label": "DDD3",
        "value": "ddd3"
    },
    {
        "label": "Telefone 3",
        "value": "telefone3"
    },
    {
        "label": "Telefone com WhatsApp 1",
        "value": "telefonewhatsapp1"
    },
    {
        "label": "Telefone com WhatsApp 2",
        "value": "telefonewhatsapp2"
    },
    {
        "label": "Telefone com WhatsApp 3",
        "value": "telefonewhatsapp3"
    },
    {
        "label": "E-mail 1",
        "value": "email1"
    },
    {
        "label": "Site",
        "value": "site"
    },
    {
        "label": "Setor",
        "value": "setor"
    },
    {
        "label": "Faturamento Anual",
        "value": "faturamentoanual"
    },
    {
        "label": "Faturamento Mensal",
        "value": "faturamentomensal"
    },
    {
        "label": "Porte",
        "value": "porte"
    },
    {
        "label": "Número de funcionários",
        "value": "numerofuncionario"
    },
    {
        "label": "Número Sócio",
        "value": "numerosocio"
    },
    {
        "label": "Data de abertura",
        "value": "dataabertura"
    },
    {
        "label": "Tipo Empresa",
        "value": "tipoempresa"
    },
    {
        "label": "Socio 1 Nome Completo",
        "value": "socio1nome"
    },
    {
        "value": "socio1primeironome",
        "label": "Socio1 Primeiro Nome"
    },
    {
        "value": "socio1sobrenome",
        "label": "Socio1 Sobrenome"
    },
    {
        "value": "socio1cargo",
        "label": "Socio1 Cargo"
    },
    {
        "value": "socio1doc",
        "label": "Socio1 Doc"
    },
    {
        "value": "socio1ddd1",
        "label": "Socio1 ddd1",
    },
    {
        "value": "socio1telefone1",
        "label": "Socio1 telefone1",
    },
    {
        "value": "socio1ddd2",
        "label": "Socio1 ddd2",
    },
    {
        "value": "socio1telefone2",
        "label": "Socio1 telefone2",
    },
    {
        "value": "socio1email1",
        "label": "Socio1 email1",
    },
    {
        "value": "socio2nome",
        "label": "Socio2 Nome Completo"
    },
    {
        "value": "socio2primeironome",
        "label": "Socio2 Primeiro Nome"
    },
    {
        "value": "socio2sobrenome",
        "label": "Socio2 Sobrenome"
    },
    {
        "value": "socio2qualificacao",
        "label": "Socio2 Qualificação"
    },
    {
        "value": "socio2doc",
        "label": "Socio2 Doc"
    },
    {
        "value": "socio2ddd1",
        "label": "Socio2 ddd1",
    },
    {
        "value": "socio2telefone1",
        "label": "Socio2 telefone1",
    },
    {
        "value": "socio2ddd2",
        "label": "Socio2 ddd2",
    },
    {
        "value": "socio2telefone2",
        "label": "Socio2 telefone2",
    },
    {
        "value": "socio2email1",
        "label": "Socio2 email1",
    },
    {
        "value": "socio1telefonewhatsapp1",
        "label": "Socio1 telefone whatsapp 1",
    },
    {
        "value": "socio1telefonewhatsapp2",
        "label": "Socio1 telefone whatsapp 2",
    },
    {
        "value": "socio2telefonewhatsapp1",
        "label": "Socio2 telefone whatsapp 1",
    },
    {
        "value": "socio2telefonewhatsapp2",
        "label": "Socio2 telefone whatsapp 2",
    },
    {
        "value": "icpIa",
        "label": "ICP - IA",
    }
]

export default extraCols;