import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";

interface CreateModelModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (name: string, modelType: string, companyId: string) => void;
  PaperProps?: React.ComponentProps<typeof Dialog>["PaperProps"];
  loading: boolean;
  companyId: string;
}

export const CreateModelModal: React.FC<CreateModelModalProps> = ({
  open,
  onClose,
  onSave,
  PaperProps,
  loading,
  companyId,
}) => {
  const [modelName, setModelName] = useState<string>("");
  // Define o modelo como "support-vector" por padrão
  const [modelType] = useState<string>("support-vector");

  const handleSave = () => {
    if (!modelName.trim()) {
      alert("Preencha todos os campos obrigatórios!");
      return;
    }
    onSave(modelName, modelType, companyId);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: PaperProps?.style,
      }}
    >
      <DialogTitle>Criar Novo Modelo</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Nome do Modelo"
          value={modelName}
          onChange={(e) => setModelName(e.target.value)}
          margin="normal"
        />
        <label style={{ marginBottom: "8px", display: "block" }}>
          Tipo de Modelo
        </label>
        {/* Campo apenas para exibição, não editável */}
        <TextField
          fullWidth
          value="Support Vector"
          disabled
          variant="outlined"
          margin="dense"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Criar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
