import { useCallback, useState } from "react";
import { useDataScienceApi } from "../../infra/data-science-api";
import { useLoadingService } from "../loading";
import { useNavigate } from "react-router-dom";
import { IMLModelResume } from "../../application/models/IMLModelResume";
import { useUserService } from "../user";
import { useAppSelector } from "../../application/states/hooks";

interface TrainModelPayload {
  id: string;
  name_model: string;
  createdBy: string;
  modelType: "random-forest" | "support-vector";
  good_data: Record<string, any>[];
  bad_data: Record<string, any>[];
  express: boolean;
  seed: number;
}

export const useDataScienceService = () => {
  const navigate = useNavigate();
  const { findById } = useUserService(); // Método para buscar usuários
  const { user } = useAppSelector((state) => state)

  const { startLoading, stopLoading } = useLoadingService();
  const { trainModelApi, getModelsListApi, renameModelApi, createModelApi, updateModelStatusApi, getModelProgressApi, getAllModelsApi } = useDataScienceApi();
  const [modelsList, setModelsList] = useState<IMLModelResume[]>([]);
 

  // **Função principal: busca modelos com criadores**
  const getModelsListWithCreators = useCallback(
    async (page: number, pageSize: number, userId: string): Promise<{ data: IMLModelResume[]; total: number }> => {
      startLoading();
      try {
        
        const response = await getModelsListApi(page, pageSize, userId);
  
        const modelsWithDetails = await Promise.all(
          response.data.map(async (model) => {
            const creator = await findById({ userId: model.createdBy }).catch(() => null);
            return {
              ...model,
              createdByName: creator?.name || model.createdByName || "Desconhecido",
            };
          }));
  
        setModelsList(modelsWithDetails);
  
        return { data: modelsWithDetails, total: modelsWithDetails.length };
      } catch (error) {
        console.error("[DataScienceService] Erro ao buscar modelos", error);
        return { data: [], total: 0 };
      } finally {
        stopLoading();
      }
    },
    [startLoading, stopLoading, getModelsListApi, setModelsList, findById]
  );

  const getAllModels = useCallback(async (userId: string): Promise<IMLModelResume[]> => {
    startLoading();
    try {
      const models = await getAllModelsApi(userId);
      setModelsList(models);
      return models;
    } catch (error: any) {
      
      return [];
    } finally {
      stopLoading();
    }
  }, [getAllModelsApi, startLoading, stopLoading]);
  




  // **Função para buscar modelos sem processamento adicional**
  const getModelsList = useCallback(async (): Promise<IMLModelResume[]> => {
    try {
      const { data } = await getModelsListApi(1, 10, user.id); // Desestruturar corretamente
      return data.map((model) => ({
        id: model.id,
        name: model.name,
        modelsML_id: model.modelsML_id,
        createdByName: model.createdByName,
        userName: model.userName,
        createdBy: model.createdBy || "Desconhecido",
        createdAt: model.createdAt,
        modelType: model.modelType,
        active: model.active ?? true,
        companyId: model.companyId || "Sem empresa",
      }));
    } catch (error) {
      return [];
    }
  }, [getModelsListApi, user.id]);




  // **Função para renomear modelo**
  const renameModel = useCallback(
    async (modelId: string, newName: string, userId: string) => {
      startLoading();
      try {
        const updatedModel = await renameModelApi(modelId, newName, userId);

        // Atualiza o estado da lista de modelos com o modelo atualizado
        setModelsList((prev: IMLModelResume[]) =>
          prev.map((model) =>
            model.id === modelId ? { ...model, name: updatedModel.name } : model
          )
        );
      } catch (error) {

      } finally {
        stopLoading();
      }
    },
    [renameModelApi, startLoading, stopLoading]
  );



  // **Função para criar modelo**
  const createModel = useCallback(
    async (name: string, userId: string, modelType: string, companyId: string): Promise<IMLModelResume> => {
      try {
        const response = await createModelApi(name, userId, modelType, companyId); // Chama a API

        return response; // Retorna o modelo criado
      } catch (error) {
        throw error;
      }
    },
    [createModelApi]
  );


  // **Função para atualizar status do modelo**
  const updateModelStatus = useCallback(
    async ({ modelId, status }: { modelId: string; status: boolean }) => {
      startLoading();
      try {
        await updateModelStatusApi({ modelId, status });
        const updatedModels = await getModelsListWithCreators(1, 10, user.id); // Chamada permanece
        setModelsList(updatedModels.data);
      } catch (error) {
        
      } finally {
        stopLoading();
      }
    },
    [updateModelStatusApi, startLoading, stopLoading, getModelsListWithCreators, user.id] // Removido `getModelsListWithCreators`
  );


  // **Função para abrir modelo**
  const openModel = useCallback(
    (data: { modelId: string }) => {
      navigate(`/workspace/data-science/details`);
    },
    [navigate]
  );

  // **Função para treinar o modelo**
  const trainModel = useCallback(
    async (payload: TrainModelPayload): Promise<void> => {
      startLoading();
      try {
        await trainModelApi(payload); // Chama a API centralizada para treinamento
      } catch (error: any) {
      } finally {
        stopLoading();
      }
    },
    [trainModelApi, startLoading, stopLoading]
  );


  return {
    getAllModels,
    getModelsList,
    getModelsListWithCreators,
    openModel,
    renameModel,
    createModel,
    updateModelStatus,
    trainModel,
    getModelProgressApi,
    modelsList
  };
};