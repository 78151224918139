import * as React from 'react';
import { styled, alpha, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import LurikLogo from '../../../../assets/img/lurik-logo-white.png';
import { useNavigationService } from '../../../../../use-cases/navigation';
import { Avatar, Skeleton, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import CottageIcon from '@mui/icons-material/Cottage';
import { BubbleChart, CloudUpload, FileDownload, VerifiedUser } from '@mui/icons-material';
import { useAppSelector } from '../../../../../application/states/hooks';
import { useUnitSearchService } from '../../../../../use-cases/unit-search/useUnitSearchService';
import { useSettingsService } from '../../../../../use-cases/settings';
import { useLoginService } from '../../../../../use-cases/login';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect } from 'react';
import { darkenColor } from '../../../../../utils';
import SmartToyIcon from '@mui/icons-material/SmartToy';
export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: '95%',
  },
}));


const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
  },
}));


const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%'
  },
}));

export function Header() {
  /* get state values */
  const navigationState = useAppSelector((state) => state.navigation)
  const userState = useAppSelector((state) => state.user)
  const [loading, setLoading] = React.useState<boolean>(true)
  const company = useAppSelector((state) => state.company)
  const user = useAppSelector((state) => state.user)
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)
  const unitSearch = useAppSelector((state) => state.unitSearch)

  // Theme
  const theme = useTheme()

  /* import Services */
  const { navigateTo } = useNavigationService()
  const { setActiveTab } = useSettingsService()
  const { search, setSearchTerm } = useUnitSearchService()
  const { signOut } = useLoginService()

  // Set the active route to header styles
  useEffect(() => {
    if (window.document.location.pathname === '/workspace') {
      navigateTo({
        route: '/workspace'
      })
    }

    if (window.document.location.pathname === '/workspace/backoffice') {
      navigateTo({
        route: '/workspace/backoffice'
      })
    }

    if (window.document.location.pathname === '/workspace/data-enrichment') {
      navigateTo({
        route: '/workspace/data-enrichment'
      })
    }

    if (window.document.location.pathname === '/workspace/flags') {
      navigateTo({
        route: '/workspace/flags'
      })
    }

    if (window.document.location.pathname === '/workspace/project-exports') {
      navigateTo({
        route: '/workspace/project-exports'
      })
    }

    if (window.document.location.pathname === '/workspace/projects') {
      navigateTo({
        route: '/workspace/projects'
      })
    }

    if (window.document.location.pathname === '/workspace/projects/details') {
      navigateTo({
        route: '/workspace/projects/details'
      })
    }

    if (window.document.location.pathname === '/workspace/settings') {
      navigateTo({
        route: '/workspace/settings'
      })
    }

    if (window.document.location.pathname === '/workspace/settings/user') {
      navigateTo({
        route: '/workspace/settings/user'
      })
    }

    if (window.document.location.pathname === '/workspace/unit-search') {
      navigateTo({
        route: '/workspace/unit-search'
      })
    }

    if (window.document.location.pathname === '/workspace/unit-search/details') {
      navigateTo({
        route: '/workspace/unit-search/details'
      })
    }

  }, [navigateTo])

  useEffect(() => {
    setLoading(false)
  }, [])

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => {
        handleMenuClose()
        navigateTo({
          route: '/workspace/settings'
        })
        setActiveTab({
          tab: 'profile'
        })

      }}>Perfil</MenuItem>
      <MenuItem onClick={() => {
        handleMenuClose()
        navigateTo({
          route: '/workspace/settings',
        })
        setActiveTab({
          tab: 'profile'
        })
      }}>Configurações</MenuItem>
      <MenuItem onClick={() => {
        handleMenuClose()
        signOut()
      }}>Sair</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="inherit">Workspace</Typography>
              <Typography color="inherit">Manage your workspace</Typography>
            </React.Fragment>
          }
        >
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge badgeContent={17} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </HtmlTooltip>
        <p>Notificações</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <img src={company.company?.img} alt="12" />
        </IconButton>
        <p>Perfil</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Button onClick={() => {
            navigateTo({
              route: '/workspace'
            })
          }}
          >
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              {
                loading ? <>
                  <Skeleton variant="rounded" width={100} height={40} />
                </>
                  : <img
                    src={resaleConfig?.id !== '' ? resaleConfig?.img : LurikLogo} alt="Logo"
                    style={{
                      filter: resaleConfig?.id !== '' ? undefined : 'brightness(0) invert(1)',
                      width: '100px',
                      maxHeight: '60px'
                    }}
                  />
              }
            </Box>
          </Button>
          <form style={{
            width: '100%'
          }}
            onSubmit={(e) => {
              e.preventDefault()
              search()
            }}
          >
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                endAdornment={<>
                  <Button sx={{
                    '&:hover': {
                      backgroundColor: darkenColor(theme.palette.secondary.main, 15),
                      transition: '0.1s all'
                    }
                  }}
                    variant="contained"
                    type="submit"
                    color="secondary"
                    onClick={() => {
                    }}>
                    <Typography color={theme.palette.primary.contrastText} fontWeight="bold">
                      Pesquisar
                    </Typography>
                  </Button>
                </>
                }
                onChange={(event) => {
                  setSearchTerm({
                    searchTerm: event.target.value
                  })
                }}
                fullWidth
                value={unitSearch.searchTerm}
                placeholder="Digite CNPJ ou Razão Social da empresa para buscar"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </form>
          {/* <Box sx={{ flexGrow: 1 }} /> */}
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {/* <ClickAwayListener onClickAway={handleTooltipClose}>
              <div style={{ display: 'flex' }}>
                <HtmlTooltip
                  open={openNotificationTooltip}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <React.Fragment>
                      <List sx={{
                        width: 260,
                        height: 360,
                        bgcolor: 'background.paper',
                        overflowY: 'scroll',
                        boxShadow: 10
                      }}>
                        <Box display="flex" mb={0.5}>
                          <Grid container justifyContent={'center'}>
                            <Grid item xs={6} textAlign={'center'}>
                              <Button color="primary" sx={{
                                borderBottom: notificationState.viewMode === "NOT_VIEWED" ? `2px solid ${theme.palette.primary.main}` : '2px solid #ebebeb',
                                '&:hover': {
                                  backgroundColor: '#f5f5f5'
                                }
                              }}
                                onClick={(e) => {
                                  openNotViewedNotifications()
                                }}
                                fullWidth
                              >
                                Não lidas
                              </Button>
                            </Grid>
                            <Grid item xs={6} textAlign={'center'}>
                              <Button color="secondary" sx={{
                                borderBottom: notificationState.viewMode === 'VIEWED' ? `2px solid ${theme.palette.secondary.main}` : '2px solid #ebebeb',
                                '&:hover': {
                                  backgroundColor: '#f5f5f5'
                                }
                              }}
                                onClick={(e) => {
                                  openViewedNotifications()
                                }}
                                fullWidth
                              >
                                Lidas
                              </Button>
                            </Grid>
                          </Grid>
                          <Divider sx={{ color: '#000' }} />
                        </Box>
                        {
                          notificationState.viewMode === "NOT_VIEWED" ?
                            notificationState.notViewedNotificationList.length === 0 ?
                              <Grid container display='flex' alignContent='center' justifyContent="center" height='80%'>
                                <Grid item xs={12} sx={{ textAlign: 'center' }} px={2}>
                                  <NotificationsOffIcon sx={{
                                    color: theme.palette.text.primary,
                                    width: 50,
                                    height: 50
                                  }}
                                  />
                                </Grid>
                                <Grid item xs={12} px={2} py={1}>
                                  <Typography variant='body2' textAlign={'center'} color={`${theme.palette.text.primary}`}>
                                    Não há nada de novo por aqui! Clique em
                                    <span
                                      style={{
                                        color: `${theme.palette.secondary.main}`,
                                        cursor: 'pointer'
                                      }}
                                      onClick={openViewedNotifications}
                                    >
                                      {` "LIDAS" `}
                                    </span>
                                    para visualizar notificações antigas.
                                  </Typography>
                                </Grid>
                              </Grid>
                              :
                              notificationState.notViewedNotificationList.slice(0, notificationState.viewLimit).map((notification, index) => {
                                return <>
                                  <ListItem alignItems="flex-start" sx={{
                                    cursor: 'pointer',
                                    paddingY: 2.2,
                                    position: 'relative',
                                    '&:hover': {
                                      backgroundColor: '#f5f5f5'
                                    }
                                  }}
                                    onClick={() => {
                                      selectNotification({
                                        notification: notification
                                      })
                                      handleTooltipClose()
                                    }}
                                  >
                                    <div style={{
                                      position: 'absolute',
                                      top: '5%',
                                      right: '5%',
                                      width: '10px',
                                      height: '10px',
                                      borderRadius: '50%',
                                      backgroundColor: notification.viewedBy?.includes(userState.id) ? '' : theme.palette.primary.main
                                    }}
                                    >
                                    </div>

                                    <ListItemAvatar>
                                      {
                                        notification.img ?
                                          <Avatar alt={notification.title} src={notification.img} />
                                          :
                                          <Avatar><NotificationsIcon /></Avatar>
                                      }
                                    </ListItemAvatar>
                                    <ListItemText
                                      sx={{ justifyContent: 'center' }}
                                      primary={<>
                                        <Typography
                                          sx={{ display: 'inline' }}
                                          component="span"
                                          variant="body1"
                                          fontWeight={"bold"}
                                          color="text.primary"
                                        >
                                          {notification.title}
                                        </Typography>
                                      </>}
                                      secondary={
                                        <React.Fragment>
                                          <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                          >
                                            {notification.message}
                                          </Typography>
                                          <br />
                                          <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            fontWeight={'bold'}
                                            color="text.primary"
                                          >
                                            {format(new Date(notification.createdAt), 'dd/MM/yyyy - HH:mm ')}
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                  </ListItem>
                                  <Divider variant="inset" component="li" />
                                </>
                              })
                            :
                            notificationState.viewedNotificationList.length === 0 ?
                              <Grid container display='flex' alignContent='center' justifyContent="center" height='80%'>
                                <Grid item xs={12} sx={{ textAlign: 'center' }} px={2}>
                                  <NotificationsOffIcon sx={{
                                    color: theme.palette.text.primary,
                                    width: 50,
                                    height: 50
                                  }}
                                  />
                                </Grid>
                                <Grid item xs={12} px={2} py={1}>
                                  <Typography variant='body2' textAlign={'center'} color={`${theme.palette.text.primary}`}>
                                    Não há nada por aqui! Clique em
                                    <span
                                      style={{
                                        color: `${theme.palette.primary.main}`,
                                        cursor: 'pointer',
                                        fontWeight: 'bold'
                                      }}
                                      onClick={openNotViewedNotifications}
                                    >
                                      {` "NÃO LIDAS" `}
                                    </span>
                                    para visualizar novas notificações.
                                  </Typography>
                                </Grid>
                              </Grid>
                              :
                              notificationState.viewedNotificationList.slice(0, notificationState.viewLimit).map((notification, index) => {
                                return <>
                                  <ListItem alignItems="flex-start" sx={{
                                    cursor: 'pointer',
                                    paddingY: 2.2,
                                    position: 'relative',
                                    '&:hover': {
                                      backgroundColor: '#f5f5f5'
                                    }
                                  }}
                                    onClick={() => {
                                      selectNotification({
                                        notification: notification
                                      })
                                      handleTooltipClose()
                                    }}
                                  >
                                    <div style={{
                                      position: 'absolute',
                                      top: '5%',
                                      right: '5%',
                                      width: '10px',
                                      height: '10px',
                                      borderRadius: '50%',
                                      backgroundColor: notification.viewedBy?.includes(userState.id) ? '' : theme.palette.primary.main
                                    }}
                                    >

                                    </div>
                                    <ListItemAvatar>
                                      {
                                        notification.img ?
                                          <Avatar alt={notification.title} src={notification.img} />
                                          :
                                          <Avatar ><NotificationsIcon /></Avatar>
                                      }
                                    </ListItemAvatar>
                                    <ListItemText
                                      sx={{ justifyContent: 'center' }}
                                      primary={<>
                                        <Typography
                                          sx={{ display: 'inline' }}
                                          component="span"
                                          variant="body1"
                                          fontWeight={"bold"}
                                          color="text.primary"
                                        >
                                          {notification.title}
                                        </Typography>
                                      </>}
                                      secondary={
                                        <React.Fragment>
                                          <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                          >
                                            {notification.message}
                                          </Typography>
                                          <br />
                                          <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            fontWeight={'bold'}
                                            color="text.primary"
                                          >
                                            {format(new Date(notification.createdAt), 'dd/MM/yyyy - HH:mm ')}
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                  </ListItem>
                                  <Divider variant="inset" component="li" />
                                </>
                              })
                        })
                        {
                          notificationState.viewMode === "NOT_VIEWED" ?
                            notificationState.viewLimit < notificationState.notViewedNotificationList.length ?
                              <Box justifyContent={"center"} display="flex" my={2}>
                                <Button color="primary" onClick={() => {
                                  expandNotificationView()
                                }}>
                                  Ver mais
                                </Button>
                              </Box> :
                              null
                            :
                            notificationState.viewLimit < notificationState.viewedNotificationList.length ?
                              <Box justifyContent={"center"} display="flex" my={2}>
                                <Button color="secondary" onClick={() => {
                                  expandNotificationView()
                                }}>
                                  Ver mais
                                </Button>
                              </Box> :
                              null
                        }
                      </List>
                    </React.Fragment>
                  }
                >
                  <IconButton
                    onClick={() => {
                      setOpenNotificationTooltip(true)
                    }}
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                  >
                    <Badge badgeContent={notificationState.notViewedNotificationList.length} color="error">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </HtmlTooltip>
              </div>
            </ClickAwayListener> */}

            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Avatar alt={user.name} src={user.img} />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* <Dialog
        open={notificationState.openNotificationDialog}
        onClose={closeNotificationDialog}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={notificationState.selectedNotification?.img ? "sm" : "xs"}
      >
        <DialogTitle id="scroll-dialog-title">{notificationState.selectedNotification?.title ?? ''}
          <Typography display={'block'} variant='caption'>
            {format(new Date(notificationState.selectedNotification?.createdAt ?? new Date()), 'dd/MM/yyyy - HH:mm ')}
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent >
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
            py={1}
          >
            {notificationState.selectedNotification?.message}
          </DialogContentText>
          {
            notificationState.selectedNotification?.img ?
              <DialogContentText
                tabIndex={-1}
                py={1}
              >
                <Divider />
                <img src={notificationState.selectedNotification?.img ?? ''} alt='' style={{
                  width: '100%'
                }} />
              </DialogContentText>
              : null
          }
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={resumeNotification}
            sx={{
              fontWeight: 900,
              color: notificationState.resumeNotificationTextColor === 'primary'
                ? theme.palette.primary.main
                : theme.palette.secondary.main
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog> */}

      <AppBar
        position="static"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Toolbar>
          <Box mx={2} sx={{
            borderRadius: '30px',
            backgroundColor:
              navigationState.activeRoute === '/workspace' ||
                navigationState.activeRoute === '/workspace/unit-search' ||
                navigationState.activeRoute === '/workspace/unit-search/details'
                ? theme.palette.secondary.main
                : theme.palette.primary.main,
            '&:hover': {
              backgroundColor:
                navigationState.activeRoute === '/workspace' ||
                  navigationState.activeRoute === '/workspace/unit-search' ||
                  navigationState.activeRoute === '/workspace/unit-search/details'
                  ? darkenColor(theme.palette.secondary.main, 15)
                  : darkenColor(theme.palette.primary.main, 15),
              transition: '0.1s all'
            }
          }}>
            <Button
              onClick={() => {
                navigateTo({
                  route: '/workspace'
                })
              }}
              style={{
                borderRadius: '30px',
                padding: '10px 30px',
                backgroundColor: 'inherit'
              }}
              color={
                navigationState.activeRoute === '/workspace' ||
                  navigationState.activeRoute === '/workspace/unit-search' ||
                  navigationState.activeRoute === '/workspace/unit-search/details' ? 'secondary' : 'primary'
              }
              variant="contained"
              endIcon={
                <CottageIcon
                  style={{
                    color: 'white'
                  }}
                />
              }
            >
              <Typography color="white" fontWeight={"bold"}>
                Home
              </Typography>
            </Button>
          </Box>

          <Box mx={2} sx={{
            borderRadius: '30px',
            backgroundColor:
              navigationState.activeRoute === '/workspace/projects'
                || navigationState.activeRoute === '/workspace/projects/details'
                ? theme.palette.secondary.main
                : theme.palette.primary.main,
            '&:hover': {
              backgroundColor:
                navigationState.activeRoute === '/workspace/projects'
                  || navigationState.activeRoute === '/workspace/projects/details'
                  ? darkenColor(theme.palette.secondary.main, 15)
                  : darkenColor(theme.palette.primary.main, 15),
              transition: '0.1s all'
            }
          }}>
            <Button
              onClick={() => {
                navigateTo({
                  route: '/workspace/projects'
                })
              }}
              style={{
                borderRadius: '30px',
                padding: '10px 30px',
                backgroundColor: 'inherit'
              }}
              color={navigationState.activeRoute === '/workspace/projects' ? 'secondary' : 'primary'}
              variant="contained"
              endIcon={
                <BubbleChart
                  style={{
                    color: 'white'
                  }}
                />
              }>
              <Typography color="white" fontWeight={"bold"}>
                Projetos
              </Typography>
            </Button>
          </Box>
          <Box mx={2} sx={{
            borderRadius: '30px',
            backgroundColor:
              navigationState.activeRoute === '/workspace/data-enrichment'
                ? theme.palette.secondary.main
                : theme.palette.primary.main,
            '&:hover': {
              backgroundColor:
                navigationState.activeRoute === '/workspace/data-enrichment'
                  ? darkenColor(theme.palette.secondary.main, 15)
                  : darkenColor(theme.palette.primary.main, 15),
              transition: '0.1s all'
            }
          }}>
            <Button
              onClick={() => {
                navigateTo({
                  route: '/workspace/data-enrichment'
                })
              }}
              style={{
                borderRadius: '30px',
                padding: '10px 30px',
                backgroundColor: 'inherit'
              }}
              color={navigationState.activeRoute === '/workspace/data-enrichment' ? 'secondary' : 'primary'}
              variant="contained"
              endIcon={
                <CloudUpload
                  style={{
                    color: 'white'
                  }}
                />
              }>
              <Typography color="white" fontWeight={"bold"}>
                Enriquecimento
              </Typography>
            </Button>
          </Box>
          <Box mx={2} sx={{
            borderRadius: '30px',
            backgroundColor:
              navigationState.activeRoute === '/workspace/data-science'
                || navigationState.activeRoute === '/workspace/data-science/details'
                || navigationState.activeRoute === '/workspace/data-science/create'
                ? theme.palette.secondary.main
                : theme.palette.primary.main,
            '&:hover': {
              backgroundColor:
                navigationState.activeRoute === '/workspace/data-science'
                  || navigationState.activeRoute === '/workspace/data-science/details'
                  || navigationState.activeRoute === '/workspace/data-science/create'
                  ? darkenColor(theme.palette.secondary.main, 15)
                  : darkenColor(theme.palette.primary.main, 15),
              transition: '0.1s all'
            }
          }}>
            <Button onClick={() => {
              navigateTo({
                route: '/workspace/data-science'
              })
            }}
              style={{
                borderRadius: '30px',
                padding: '10px 30px',
                backgroundColor: 'inherit'
              }}
              color={(
                navigationState.activeRoute === '/workspace/data-science' ||
                navigationState.activeRoute === '/workspace/data-science/details' ||
                navigationState.activeRoute === '/workspace/data-science/create'
              ) ? 'secondary' : 'primary'}
              variant="contained"
              endIcon={
                <SmartToyIcon
                  style={{
                    color: 'white'
                  }}
                />
              }>
              <Typography color="white" fontWeight={"bold"}>
                Data Science
              </Typography>
            </Button>
          </Box>

          <Box mx={2} sx={{
            borderRadius: '30px',
            backgroundColor:
              navigationState.activeRoute === '/workspace/project-exports'
                ? theme.palette.secondary.main
                : theme.palette.primary.main,
            '&:hover': {
              backgroundColor:
                navigationState.activeRoute === '/workspace/project-exports'
                  ? darkenColor(theme.palette.secondary.main, 15)
                  : darkenColor(theme.palette.primary.main, 15),
              transition: '0.1s all'
            }
          }}>
            <Button
              onClick={() => {
                navigateTo({
                  route: '/workspace/project-exports'
                })
              }}
              style={{
                borderRadius: '30px',
                padding: '10px 30px',
                backgroundColor: 'inherit'
              }}
              color={navigationState.activeRoute === '/workspace/project-exports' ? 'secondary' : 'primary'}
              variant="contained"
              endIcon={
                <FileDownload
                  style={{
                    color: 'white'
                  }}
                />
              }>
              <Typography color="white" fontWeight={"bold"}>
                Extrações
              </Typography>
            </Button>
          </Box>



          {
            company.company?.master === true || company.company?.resale === true ?
              userState.userType === "OWNER" || userState.userType === "ADMIN" || userState.userType === "SELLER"
                ?
                <Box mx={2} sx={{
                  borderRadius: '30px',
                  backgroundColor:
                    navigationState.activeRoute === '/workspace/backoffice'
                      ? theme.palette.secondary.main
                      : theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor:
                      navigationState.activeRoute === '/workspace/backoffice'
                        ? darkenColor(theme.palette.secondary.main, 15)
                        : darkenColor(theme.palette.primary.main, 15),
                    transition: '0.1s all'
                  }
                }}>
                  <Button onClick={() => {
                    navigateTo({
                      route: '/workspace/backoffice'
                    })
                  }}
                    style={{
                      borderRadius: '30px',
                      padding: '10px 30px',
                      backgroundColor: 'inherit'
                    }}
                    color={
                      navigationState.activeRoute === '/workspace/backoffice' ? 'secondary' : 'primary'
                    }
                    variant="contained"
                    endIcon={
                      <VerifiedUser
                        style={{
                          color: 'white'
                        }}
                      />
                    }
                  >
                    <Typography color="white" fontWeight={"bold"}>
                      Backoffice
                    </Typography>
                  </Button>
                </Box>
                : null
              : null
          }
        </Toolbar>
      </AppBar>

      {renderMobileMenu}
      {renderMenu}
    </Box >
  );
}






