import {
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Slide,
  Button,
  Grid,
  Badge,
  styled,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDataScienceApi } from "../../../../infra/data-science-api";
import readExcelFile from "read-excel-file";
import { useDataScienceService } from "../../../../use-cases/data-science";
import { read, utils } from "xlsx";
import Papa from "papaparse";
import { ToastAlert } from "../../../toast-alert";
import { executeAlert } from "../../../../application/states/toast-alert";
import { useAppDispatch } from "../../../../application/states/hooks";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';


// Tipo do payload do treino
interface TrainModelPayload {
  id: string;
  name_model: string;
  createdBy: string;
  modelType: "random-forest" | "support-vector";
  good_data: Record<string, any>[];
  bad_data: Record<string, any>[];
  express: boolean;
  seed: number;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const DataScienceModelDetailsPage = () => {
  const { setModelPendingBeforeTraining } = useDataScienceApi();
  const { trainModel } = useDataScienceService();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch(); // Adicionado o dispatch correto do Redux

  // Receber os dados do modelo diretamente do estado
  const selectedModel = location.state?.model;

  const [goodFileName, setGoodFileName] = useState<string | null>(null);
  const [badFileName, setBadFileName] = useState<string | null>(null);
  const [goodData, setGoodData] = useState<any[]>([]);
  const [badData, setBadData] = useState<any[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedModelType, setSelectedModelType] =
    useState<"random-forest" | "support-vector" | null>(
      selectedModel?.modelType || null
    );
      // Função para formatar números a partir de 1.000 com ponto (.) como separador decimal
  const formatNumberWithDots = (num: number): string => {
    return num.toLocaleString("pt-BR");
  };

  if (!selectedModel || !selectedModel.createdBy) {
    navigate("/workspace/data-science");
    return null;
  }

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  
  const handleClose = () => {
    setOpenDialog(false);
  };


  // Função para processar o arquivo
  const handleUploadFile = async (
    files: FileList,
    type: "good_data" | "bad_data"
  ) => {
    const file = files[0];
    const fileName = file.name.toLowerCase();
    const fileExtension = fileName.split(".").pop();


    try {
      let formattedData: Record<string, any>[] = [];

      if (fileExtension === "xlsx") {
        // Processa arquivos .xlsx com readExcelFile
        const rows = await readExcelFile(file);
        const headers = (rows[0] as string[]).map((header) =>
          header.toLowerCase()
        );
        const dataRows = rows.slice(1);

        formattedData = dataRows.map((row) =>
          headers.reduce((acc, header, index) => {
            acc[header] = row[index];
            return acc;
          }, {} as Record<string, any>)
        );
      } else if (fileExtension === "xls") {
        // Processa arquivos .xls com xlsx
        const data = await file.arrayBuffer(); // Converte para ArrayBuffer
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0]; // Seleciona a primeira planilha
        const sheet = workbook.Sheets[sheetName];
        formattedData = utils.sheet_to_json(sheet);
      } else if (fileExtension === "csv" || fileExtension === "txt") {
        // Processa arquivos .csv e .txt com PapaParse
        const text = await file.text(); // Lê o conteúdo como string
        const parsed = Papa.parse(text, {
          header: true, // Exige que o cabeçalho esteja presente
          skipEmptyLines: true, // Ignora linhas vazias
          delimiter: ",", // Define vírgula como delimitador padrão
        });

        // Verifica se há erros no parsing
        if (parsed.errors.length) {
          dispatch(
            executeAlert({
              message:
                "Erro ao processar o arquivo. Por favor, tente novamente.",
              type: "error",
            })
          );
          throw new Error("Erro ao processar CSV/TXT");
        }

        // Garante que há um cabeçalho válido
        if (!parsed.meta.fields || parsed.meta.fields.length === 0) {
          dispatch(
            executeAlert({
              message:
                "O arquivo não contém cabeçalho ou está vazio. Por favor, tente novamente.",
              type: "error",
            })
          );
          throw new Error("O arquivo CSV/TXT não contém cabeçalho ou está vazio.");
        }

        formattedData = parsed.data as Record<string, any>[];

        // Se o arquivo tiver apenas uma coluna, adapta o formato
        if (parsed.meta.fields.length === 1) {
          const singleField = parsed.meta.fields[0];
          formattedData = formattedData.map((row) => ({
            [singleField]: row[singleField]?.trim(),
          }));
        }
      } else {
        dispatch(
          executeAlert({
            message:
              "Formato de arquivo não suportado. Use .xlsx, .csv ou .txt.",
            type: "error",
          })
        );
        throw new Error(
          "Formato de arquivo não suportado. Use .xlsx, .csv ou .txt."
        );
      }

      // Verifica duplicatas antes de inserir os dados
      if (type === "good_data") {
        if (badData.length > 0) {
          const { quantidadeDuplicatas, duplicatas } = verificarDuplicatas(formattedData, badData);
         
          // Remove as duplicatas
          const dadosFiltrados = formattedData.filter(item => !duplicatas.includes(item.cnpj));
          
          // Verifica se após remover duplicatas ainda tem o mínimo de 50
          if (dadosFiltrados.length < 50) {
            dispatch(
              executeAlert({
                message: `Atenção: Após remover ${formatNumberWithDots(quantidadeDuplicatas)} CNPJs duplicados, restaram apenas $${formatNumberWithDots(dadosFiltrados.length)} CNPJs para casos positivos. O mínimo recomendado é 50 CNPJs.`,
                type: "warning",
              })
            );
            const input = document.querySelector(`input[type="file"][onchange*="${type}"]`) as HTMLInputElement;
            if (input) {
              input.value = '';
            }
            setGoodFileName(null);
            setGoodData([]);
            return;
          }

          setGoodFileName(fileName);
          setGoodData(dadosFiltrados);
          
          dispatch(
            executeAlert({
              message: `Arquivo positivo processado com sucesso! ${formatNumberWithDots(quantidadeDuplicatas)} CNPJs duplicados foram removidos. ${formatNumberWithDots(dadosFiltrados.length)} CNPJs válidos foram mantidos.`,
              type: "success",
            })
          );
        } else {
          // Se não há bad_data, verifica apenas a quantidade mínima
          if (formattedData.length < 50) {
            dispatch(
              executeAlert({
                message: `Atenção: Foram carregados ${formatNumberWithDots(formattedData.length)} CNPJs para casos positivos. O mínimo recomendado é 50 CNPJs.`,
                type: "warning",
              })
            );
            const input = document.querySelector(`input[type="file"][onchange*="${type}"]`) as HTMLInputElement;
            if (input) input.value = '';
            setGoodFileName(null);
            setGoodData([]);
            return;
          }
          
          setGoodFileName(fileName);
          setGoodData(formattedData);
          dispatch(
            executeAlert({
              message: `Arquivo positivo carregado com sucesso! ${formatNumberWithDots(formattedData.length)} CNPJs processados.`,
              type: "success",
            })
          );
        }
      } else {
        // Lógica similar para bad_data
        if (goodData.length > 0) {
          const { quantidadeDuplicatas, duplicatas } = verificarDuplicatas(goodData, formattedData);
          
          // Remove as duplicatas
          const dadosFiltrados = formattedData.filter(item => !duplicatas.includes(item.cnpj));
          
          // Verifica se após remover duplicatas ainda tem o mínimo de 50
          if (dadosFiltrados.length < 50) {
            dispatch(
              executeAlert({
                message: `Atenção: Após remover ${formatNumberWithDots(quantidadeDuplicatas)} CNPJs duplicados, restaram apenas ${dadosFiltrados.length} CNPJs para casos negativos. O mínimo recomendado é 50 CNPJs.`,
                type: "warning",
              })
            );
            const input = document.querySelector(`input[type="file"][onchange*="${type}"]`) as HTMLInputElement;
            if (input) {
              input.value = '';
            }
            setBadFileName(null);
            setBadData([]);
            return;
          }

          // Se chegou aqui, pode inserir o bad_data filtrado
          setBadFileName(fileName);
          setBadData(dadosFiltrados);
          
          dispatch(
            executeAlert({
              message: `Arquivo negativo processado com sucesso! ${formatNumberWithDots(quantidadeDuplicatas)} CNPJs duplicados foram removidos. ${dadosFiltrados.length} CNPJs válidos foram mantidos.`,
              type: "success",
            })
          );
        } else {
          // Se não há good_data, verifica apenas a quantidade mínima
          if (formattedData.length < 50) {
            dispatch(
              executeAlert({
                message: `Atenção: Foram carregados ${formatNumberWithDots(formattedData.length)} CNPJs para casos negativos. O mínimo recomendado é 50 CNPJs.`,
                type: "warning",
              })
            );
            const input = document.querySelector(`input[type="file"][onchange*="${type}"]`) as HTMLInputElement;
            if (input) input.value = '';
            setBadFileName(null);
            setBadData([]);
            return;
          }
          
          setBadFileName(fileName);
          setBadData(formattedData);
          dispatch(
            executeAlert({
              message: `Arquivo negativo carregado com sucesso! ${formatNumberWithDots(formattedData.length)} CNPJs processados.`,
              type: "success",
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        executeAlert({
          message: "Erro ao carregar o arquivo. Verifique o formato e tente novamente.",
          type: "error",
        })
      );
      // Limpa o input e os dados em caso de erro
      const input = document.querySelector(`input[type="file"][onchange*="${type}"]`) as HTMLInputElement;
      if (input) {
        input.value = '';
      }
      if (type === "good_data") {
        setGoodFileName(null);
        setGoodData([]);
      } else {
        setBadFileName(null);
        setBadData([]);
      }
    }
  };

  const handleTrainModel = async () => {
    if (!selectedModelType) {
      alert("Por favor, selecione o tipo de treinamento!");
      return;
    }

    if (!selectedModel) {
      alert("Modelo não encontrado!");
      return;
    }

    selectedModel.modelType = selectedModelType;

    dispatch(
      executeAlert({
        message: "Modelo enviado para a fila de treinamento!",
        type: "success",
      })
    );

    // Redireciona imediatamente para a lista de modelos
    // navigate("/workspace/data-science");
    try {
      // Remove duplicatas antes de enviar
      const { goodDataFiltrado, badDataFiltrado } = removerDuplicatas(goodData, badData);

      const payload: TrainModelPayload = {
        id: selectedModel.id,
        name_model: selectedModel.name,
        createdBy: selectedModel.createdBy,
        modelType: selectedModelType,
        good_data: goodDataFiltrado,
        bad_data: badDataFiltrado,
        express: true,
        seed: 42,
      };

      // Atualiza o status do modelo para "pending"
      await setModelPendingBeforeTraining(selectedModel.id);

      // Envia a solicitação de treinamento ao backend
      await trainModel(payload);
    } catch (error) {
      console.error("[LOG] Erro ao enviar treinamento:", error);
    } finally {
      navigate("/workspace/data-science");
      // window.location.reload();    
    }
  };

  const verificarDuplicatas = (goodData: any[], badData: any[]) => {
    const cnpjsGood = new Set(goodData.map(item => item.cnpj));
    const cnpjsBad = new Set(badData.map(item => item.cnpj));
    
    // Convertendo para Array antes de usar filter
    const duplicatas = Array.from(cnpjsGood).filter(cnpj => cnpjsBad.has(cnpj));
    
    return {
      duplicatas,
      quantidadeDuplicatas: duplicatas.length
    };
  };

  const removerDuplicatas = (goodData: any[], badData: any[]) => {
    const cnpjsGood = new Set(goodData.map(item => item.cnpj));
    const cnpjsBad = new Set(badData.map(item => item.cnpj));
    
    const goodDataFiltrado = goodData.filter(item => !cnpjsBad.has(item.cnpj));
    const badDataFiltrado = badData.filter(item => !cnpjsGood.has(item.cnpj));
    
    return {
      goodDataFiltrado,
      badDataFiltrado
    };
  };




  return (
    <>
      <Dialog
        fullScreen
        open={true}
        onClose={() => navigate("/workspace/data-science")}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Editar modelo: {selectedModel?.name || "Carregando..."}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => navigate("/workspace/data-science")}
            >
              Fechar
            </Button>
          </Toolbar>
        </AppBar>
        <Box p={3}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Selecione o Tipo de Treinamento
                </Typography>
                <Box display="flex" justifyContent="flex-start" my={2}>
                  <select
                    value={selectedModelType || ""}
                    onChange={(e) =>
                      setSelectedModelType(
                        e.target.value as "random-forest" | "support-vector"
                      )
                    }
                    style={{
                      padding: "8px",
                      fontSize: "16px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      width: "100%", // <-- largura 100%,
                    }}
                  >
                    <option value="" disabled>
                      Escolha o tipo de modelo
                    </option>
                    <option value="random-forest">Random Forest</option>
                    <option value="support-vector">Support Vector</option>
                  </select>
                </Box>
                {/* Mensagem condicional abaixo da seleção */}
                {selectedModelType && (
                  <Box my={2} p={2} border="1px solid #ddd" borderRadius={4}>
                   <Box 
                        display="flex" 
                        flexDirection="column" 
                        alignItems="flex-start"
                        mb={2}
                        gap={1}
                      >

                      <Typography variant="subtitle1">
                      <Typography variant="h6">
                        Treinamento com <strong>{selectedModelType === "random-forest" ? "Random Forest 🌲" : "Support Vector 📐"}</strong>
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                          Mínimo de <strong>50 CNPJs</strong> para casos positivos e negativos
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                          {selectedModelType === "random-forest" 
                            ? "Random Forest: Bom quando tem muita bagunça nos dados. Ele vê vários caminhos diferentes e decide pelo mais votado. Tipo um grupo de especialistas votando."
                            : "Support Vector: Funciona bem quando os casos positivos e negativos estão claramente separados, como clientes com alto faturamento que compram e baixo faturamento que não compram. Ele traça uma linha clara entre os dois grupos para prever quem vai comprar"
                          }
                        </Typography>

                        </Typography>
                        <Button onClick={handleClickOpen} color="primary" variant="outlined" size="small">
                        {openDialog ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </Button>
                    </Box>
                    
                    <Dialog
                      open={openDialog}
                      onClose={handleClose}
                      maxWidth="sm"
                      fullWidth
                    >
                      <DialogTitle>
                        Treinamento com {selectedModelType === "random-forest" ? "Random Forest 🌲" : "Support Vector 📐"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          {selectedModelType === "random-forest" ? (
                            <>
                              <Typography variant="body2" gutterBottom>
                                <strong>O que é ?</strong> Treina um modelo baseado no
                                algoritmo Random Forest, que é um conjunto de árvores de
                                decisão usadas para classificação ou regressão. As árvores
                                votam e o resultado final é decidido por maioria.
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                <strong>Como funciona ?</strong> Recebe dois conjuntos de
                                dados: Casos Positivos e Casos Negativos. Usa os dados para
                                criar "árvores de decisão", que aprendem a classificar novos
                                exemplos com base nas características fornecidas (ex.: CNAE,
                                macroregião, faturamento anual). Avalia o modelo usando métricas
                                como accuracy, precision e recall.
                              </Typography>
                              <Typography variant="body2">
                                <strong>Para que serve ?</strong> Ideal para identificar padrões
                                complexos com boa precisão, especialmente quando as relações
                                entre as variáveis são não-lineares.

                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography variant="body2" gutterBottom>
                                <strong>O que é ?</strong> Treina um modelo baseado no
                                algoritmo Support Vector Machine (SVM), que tenta encontrar o
                                melhor hiperplano para separar as classes.
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                <strong>Como funciona ?</strong> Recebe dois conjuntos de dados:
                                Casos Positivos e Casos Negativos. Cria um hiperplano (ou superfície
                                em múltiplas dimensões) que separa os exemplos com a maior margem
                                possível. Avalia o modelo com métricas similares ao Random Forest.
                              </Typography>
                              <Typography variant="body2">
                                <strong>Para que serve ?</strong> Útil para dados de alta
                                dimensionalidade, funcionando bem quando as classes são separáveis,
                                embora possa ser sensível a ruídos.
     
                              </Typography>
                            </>
                          )}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Fechar</Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                )}
              </Box>
              <Box mt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleTrainModel}
                  disabled={!selectedModelType || !goodData.length || !badData.length}
                >
                  Iniciar Treinamento
                </Button>
              </Box>
            </Grid>

            <Grid item xs={9}>
              <Grid container spacing={2}>
                {/* Casos Positivos */}
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="center">
                    <Badge badgeContent={"!"} color="error">
                      <Typography
                        color="primary"
                        variant="h6"
                        textTransform="uppercase"
                      >
                        Casos Positivos
                      </Typography>
                    </Badge>
                  </Box>

                  <Box display="flex" justifyContent="center">
                    <Button component="label">
                      {goodFileName || "Selecione arquivo"}
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(e: any) =>
                          handleUploadFile(e.target.files, "good_data")
                        }
                      />
                    </Button>
                  </Box>
                </Grid>

                {/* Casos Negativos */}
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="center">
                    <Badge badgeContent={"!"} color="error">
                      <Typography
                        color="secondary"
                        variant="h6"
                        textTransform="uppercase"
                      >
                        Casos Negativos
                      </Typography>
                    </Badge>
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Button component="label">
                      {badFileName || "Selecione arquivo"}
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(e: any) =>
                          handleUploadFile(e.target.files, "bad_data")
                        }
                      />
                    </Button>
                  </Box>
                </Grid>
              </Grid>

              {/* Exemplo de Planilha (único) */}
              <Box mt={4}>
                <Typography variant="subtitle1" gutterBottom>
                  Exemplo de Planilha:
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  A planilha deve conter obrigatoriamente a coluna:{" "}
                  <strong>cnpj</strong>
                </Typography>
                <Box display="flex" justifyContent="center" my={2}>
                  <a
                    href="/exemplo_planilha.xlsx"
                    // caminho da planilha de exemplo
                    download="exemplo_planilha.xlsx"
                    style={{
                      fontSize: "14px",
                      textDecoration: "none",
                      color: "#802ce5",
                      fontWeight: "bold",
                    }}
                  >
                    Baixar exemplo de planilha
                  </a>
                </Box>
                <Box
                  sx={{
                    overflowX: "auto",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    padding: "8px",
                  }}
                >
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                    }}
                  >
                    <thead>
                      <tr style={{ backgroundColor: "#7754A9", color: "#FFFFFF" }}>
                        <th style={{ border: "1px solid #ddd", padding: "8px", width: "48%" }}>
                          CNPJs Positivos ({goodData.length.toLocaleString('pt-BR')} registros importados)
                        </th>
                        <th style={{ width: "4%", backgroundColor: "#fff" }}></th>
                        <th style={{ border: "1px solid #ddd", padding: "8px", width: "48%" }}>
                          CNPJs Negativos ({badData.length.toLocaleString('pt-BR')} registros importados)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ padding: 0, border: "1px solid #ddd" }}>
                          <div style={{ 
                            maxHeight: "300px", 
                            overflowY: "auto",
                            width: "100%"
                          }}>
                            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                              <tbody>
                                {(goodData.length > 0 ? goodData : Array(5).fill({ cnpj: '98765432100001' })).map((item, index) => (
                                  <tr key={index} style={{ backgroundColor: "#fff", color: "#000" }}>
                                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                                      {item.cnpj}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </td>
                        <td style={{ backgroundColor: "#fff" }}></td>
                        <td style={{ padding: 0, border: "1px solid #ddd" }}>
                          <div style={{ 
                            maxHeight: "300px", 
                            overflowY: "auto",
                            width: "100%"
                          }}>
                            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                              <tbody>
                                {(badData.length > 0 ? badData : Array(5).fill({ cnpj: '98765432100001' })).map((item, index) => (
                                  <tr key={index} style={{ backgroundColor: "#fff", color: "#000" }}>
                                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                                      {item.cnpj}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Box>
              </Box>
            </Grid>
            
          </Grid>
        </Box>
      </Dialog>
      <ToastAlert />
    </>
  );
};
