import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../../application/states/hooks"
import { resetTableFiltersInitialState, setCompanyListState, setExportListState, setProjectExportPageState, setTableFilterCompanyIdState, setTableFilterCreatedByState, setTableFilterEndDateState, setTableFilterNameState, setTableFilterStartDateState, setTableFilterTypeState, setUserListState } from "../../application/states/project-export/project-export-slice"
import { useProjectExportApi } from "../../infra/project-export"
import { useLoadingService } from "../loading"
import { executeAlert } from "../../application/states/toast-alert"
import { ICanceledProjectExport, IProjectExport } from "../../application/models"
import { useUserApi } from "../../infra"
import { useCompanyApi } from "../../infra/company-api/useCompanyApi"

export const useProjectExportService = () => {
  // Dispatch
  const dispatch = useAppDispatch()
  // API
  const { findProjectExportByUserIdApi, recreateExportFileApi, getExportStatusApi, createCanceledProjectExportApi } = useProjectExportApi()
  const { findById, findUsersBySearchTermApi } = useUserApi()
  const { findCompaniesBySearchTermApi } = useCompanyApi()
  // Service
  const { startLoading, stopLoading } = useLoadingService()
  // State
  const user = useAppSelector((state) => state.user)
  const { page, tableFilters } = useAppSelector((state) => state.projectExport)

  const getVirtualizedUserList = useCallback(async(data: {
    searchTerm?: string
    page?: number
  }) => {
    startLoading()

    findUsersBySearchTermApi({
      userId: user.id,
      page: data.page,
      searchTerm: data.searchTerm
    }).then((result) => {
      dispatch(setUserListState({
        users: result.usersData
      }))
    }).catch((error) => {
      dispatch(executeAlert({
        message: error.message,
        type: 'error'
      }))
    }).finally(() => {
      stopLoading()
    })
  }, [dispatch, findUsersBySearchTermApi, startLoading, stopLoading, user.id])

  const getVirtualizedCompaniesList = useCallback(async(data: {
    searchTerm: string
    page?: number
  }) => {
    startLoading()

    findCompaniesBySearchTermApi({
      page: data.page,
      userId: user.id,
      searchTerm: data.searchTerm
    }).then((result) => {

      dispatch(setCompanyListState({
        companies: result.companies
      }))
    }).catch((error) => {
      dispatch(executeAlert({
        message: error.message,
        type: 'error'
      }))
    }).finally(() => {
      stopLoading()
    })
  }, [dispatch, findCompaniesBySearchTermApi, startLoading, stopLoading, user.id])

  const setProjectExportList = useCallback(async () => {
    if (user.id === '' || !user.id) {
      return
    }

    startLoading()
    const projectExportList = await findProjectExportByUserIdApi({
      userId: user.id,
      limit: 10,
      page: page,
      filters: tableFilters
    })

    const exportList: {
      projectExport: IProjectExport
      fileExists: boolean
      canceledProjectExport?: ICanceledProjectExport
    }[] = []

    for (let i = 0; i < projectExportList.projectExportList.length; i++) {
      const newExportList = projectExportList.projectExportList[i]

      if (newExportList.canceledProjectExport) {
        const findUserById = await findById({
          userId: newExportList.canceledProjectExport.whoCanceled
        })

        if (findUserById) {
          exportList.push({
            projectExport: newExportList.projectExport,
            fileExists: newExportList.fileExists,
            canceledProjectExport: {
              ...newExportList.canceledProjectExport,
              userName: findUserById.name
            }
          })
        } else {
          exportList.push(newExportList)
        }
      } else {
        exportList.push(newExportList)
      }
    }

    dispatch(setExportListState({
      exportList,
      page: projectExportList.page,
      total: projectExportList.total
    }))

    stopLoading()

  }, [user.id, startLoading, findProjectExportByUserIdApi, page, tableFilters, dispatch, stopLoading, findById])

  const recreateProjectExport = useCallback((data: {
    projectExportId: string
  }) => {
    recreateExportFileApi({
      projectExportId: data.projectExportId
    }).then(() => {
      setProjectExportList()
    }).catch((err) => {
    })
  }, [recreateExportFileApi, setProjectExportList])

  const getExportStatus = useCallback((data: {
    projectExportId: string
  }): Promise<{
    status: 'pending' | 'completed' | 'failed' | 'processing',
    progress?: number
  }> => {
    return getExportStatusApi({
      projectExportId: data.projectExportId
    })
  }, [getExportStatusApi])

  const setPage = useCallback((data: {
    page: number
  }) => {
    dispatch(setProjectExportPageState(data.page))
  }, [
    dispatch,
  ])

  const cancelProjectExport = useCallback((data: {
    resourceId: string
  }) => {
    createCanceledProjectExportApi({
      resourceId: data.resourceId,
      whoCanceled: user.id
    }).then((result) => {
      setProjectExportList()
      dispatch(executeAlert({
        message: 'Extração cancelada com sucesso',
        type: 'success'
      }))
    }).catch((error) => {
      dispatch(executeAlert({
        message: error.message,
        type: 'error'
      }))
    })
  }, [createCanceledProjectExportApi, dispatch, setProjectExportList, user.id])

  const resetTableFilters = useCallback(() => {
    dispatch(resetTableFiltersInitialState())
  }, [dispatch])

  const setTableFilterCompanyId = useCallback((data: {
    companyId: string
  }) => {
    dispatch(setTableFilterCompanyIdState({
      companyId: data.companyId
    }))
  }, [dispatch])

  const setTableFilterCreatedBy = useCallback((data: {
    createdBy: string
  }) => {
    dispatch(setTableFilterCreatedByState({
      createdBy: data.createdBy
    }))
  }, [dispatch])

  const setTableFilterEndDate = useCallback((data: {
    endDate?: Date
  }) => {
    dispatch(setTableFilterEndDateState({
      endDate: data.endDate
    }))
  }, [dispatch])

  const setTableFilterName = useCallback((data: {
    name: string
  }) => {
    dispatch(setTableFilterNameState({
      name: data.name
    }))
  }, [dispatch])

  const setTableFilterStartDate = useCallback((data: {
    startDate?: Date
  }) => {
    dispatch(setTableFilterStartDateState({
      startDate: data.startDate
    }))
  }, [dispatch])

  const setTableFilterType = useCallback((data: {
    type: string
  }) => {
    dispatch(setTableFilterTypeState({
      type: data.type
    }))
  }, [dispatch])

  return {
    setProjectExportList,
    recreateProjectExport,
    getExportStatus,
    setPage,
    cancelProjectExport,
    resetTableFilters,
    setTableFilterCompanyId,
    setTableFilterCreatedBy,
    setTableFilterEndDate,
    setTableFilterName,
    setTableFilterStartDate,
    setTableFilterType,
    getVirtualizedCompaniesList,
    getVirtualizedUserList
  }
}