import { useCallback } from "react"
import { IProjectExport } from "../../application/models"
import { useAppDispatch, useAppSelector } from "../../application/states/hooks"
import { IFileExtensionOption, selectFileExtensionState, setFileExtensionInputValueState, setExportDownloadModalOpen, setSelectedProjectExportState } from "../../application/states/project-download"
import { executeAlert } from "../../application/states/toast-alert"
import { useAxiosInstances } from "../../infra"
import { useLoadingService } from "../loading"

export const useExportDownloadService = () => {
  const { axiosAuthInstance } = useAxiosInstances()
  const dispatch = useAppDispatch()
  const exportDownload = useAppSelector((state) => state.exportDownload)
  const exportLayout = useAppSelector((state) => state.exportLayout)
  const { startLoading, stopLoading } = useLoadingService()
  const userId = useAppSelector((state) => state.user.id)
  
  const openProjectExport = useCallback((data: {
    projectExport?: IProjectExport
  }) => {
    if (data.projectExport) {
      dispatch(setSelectedProjectExportState({
        selectedProjectExport: data.projectExport
      }))
      dispatch(setExportDownloadModalOpen({
        modalOpen: true
      }))
    } else {

      dispatch(executeAlert({
        message: 'Erro ao abrir exportação de projeto',
        type: 'error'
      }))
    }
  }, [dispatch])


  const closeModal = useCallback(() => {
    dispatch(setExportDownloadModalOpen({
      modalOpen: false
    }))
  }, [dispatch])

  const selectFileExtension = useCallback((data: {
    selectedFileExtension: IFileExtensionOption
  }) => {
    dispatch(selectFileExtensionState({
      selectedFileExtension: data.selectedFileExtension
    }))
  }, [dispatch])

  const setFileExtensionInputValue = useCallback((data: {
    fileExtensionInputValue: string
  }) => {
    dispatch(setFileExtensionInputValueState({
      fileExtensionInputValue: data.fileExtensionInputValue
    }))
  }, [dispatch])

  /* Download CSV File */
  const downloadProjectExport = useCallback(async () => {
    try {

      startLoading()
      const response = await axiosAuthInstance({
        url: '/project-export/download-test',
        method: 'GET',
        responseType: 'blob',
        params: {
          exportId: exportDownload.selectedProjectExport?.id,
          userId: userId,
          fileType: exportDownload.selectedFileExtension.value,
          exportLayoutId: exportLayout.selectedLayout?.id
        }
      });
      if (!(response.data instanceof Blob)) {
        console.error('[DownloadProjectExport] A resposta não é um Blob. Conteúdo:', response.data);
      }


      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${exportDownload.selectedProjectExport?.name ?? 'Extração'}.${exportDownload.selectedFileExtension.value}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      dispatch(executeAlert({
        message: 'Arquivo criado com sucesso!',
        type: 'success'
      }))
      document.body.appendChild(link);
      link.click();
    } catch (error: any) {
      console.error('[DownloadProjectExport] Erro no download:', error);

      stopLoading()
      dispatch(executeAlert({
        message: error.message,
        type: 'error'
      }))
    }

    stopLoading()
  }, [stopLoading, startLoading, axiosAuthInstance, exportDownload.selectedProjectExport?.id, exportDownload.selectedProjectExport?.name, exportDownload.selectedFileExtension.value, userId, exportLayout.selectedLayout?.id, dispatch])

  const downloadGoogleADS = useCallback(async (data: {
    projectExportId: string,
  }) => {
    try {
      startLoading()
      const response = await axiosAuthInstance({
        url: '/project-export/download',
        method: 'GET',
        responseType: 'blob',
        params: {
          projectExportId: data.projectExportId,
          fileExt: 'csv',
          exportLayoutId: 'googleads',
          createdBy: userId
        }
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `GoogleADS - ${data.projectExportId}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
    }

    stopLoading()
  }, [stopLoading, startLoading, axiosAuthInstance, userId])

  const downloadFacebookAds = useCallback(async (data: {
    projectExportId: string,
  }) => {
    try {
      startLoading()
      const response = await axiosAuthInstance({
        url: '/project-export/download',
        method: 'GET',
        responseType: 'blob',
        params: {
          projectExportId: data.projectExportId,
          fileExt: 'csv',
          exportLayoutId: 'facebookads',
          createdBy: userId

        }
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `FacebookADS - ${data.projectExportId}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
    }

    stopLoading()
  }, [stopLoading, startLoading, axiosAuthInstance, userId])
  return {
    openProjectExport,
    closeModal,
    selectFileExtension,
    setFileExtensionInputValue,
    downloadProjectExport,
    downloadGoogleADS,
    downloadFacebookAds
  }
}