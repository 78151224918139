import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMLModelResume } from '../../models/IMLModelResume';

interface IDataScienceState {
  /* Undefined deve ser o estado onde o usuário ainda não foi validado */
  modelsList: IMLModelResume[]
}

const initialState: IDataScienceState = {
  modelsList: []
};

const dataScienceSlice = createSlice({
  name: 'data-science',
  initialState,
  reducers: {
    setDataScienceMLModelsListState: (state, action: PayloadAction<IMLModelResume[]>) => {
      state.modelsList = action.payload;
    }
  },
});

export const { setDataScienceMLModelsListState } = dataScienceSlice.actions;
export const dataScienceReducer = dataScienceSlice.reducer;