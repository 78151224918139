import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../../application/states/hooks"
import { setGeolocationsFoundState, setSearchTermState, setSelectedGeolocationState, setGeolocationRadiusState, setGeolocationOpenState, setResetSelectedGeolocationState } from "../../application/states/geolocation"
import { IGeolocation } from "../../application/models"
import { useLoadingService } from "../loading"
import { executeAlert } from "../../application/states/toast-alert"
import { useOpenStreetMapGeolocationApi } from "../../infra"
import { useProjectService } from "../project"

export const useGeolocationService = () => {
    // Dispatch
    const dispatch = useAppDispatch()
    // State
    const { searchTerm, selectedGeolocation, radius } = useAppSelector((state) => state.geolocation)
    // Service
    const { startLoading, stopLoading } = useLoadingService()
    const { setGeolocationFilter, setGeolocationFilterRadius, setUseGeolocationFilter } = useProjectService()
    // API
    const { findGeolocationBySearchTermApi, findGeolocationDetailsApi, findGeolocationByPlaceIdApi, findGeolocationByLatLonApi } = useOpenStreetMapGeolocationApi()

    const setSearchTerm = useCallback((data: {
        searchTerm: string
    }) => {
        const normalizeText = data.searchTerm.replace('  ', ' ')

        dispatch(setSearchTermState({
            searchTerm: normalizeText
        }))
    }, [dispatch])

    const setSelectedGeolocation = useCallback((data: {
        geolocation: IGeolocation
    }) => {
        dispatch(setSelectedGeolocationState({
            geolocation: data.geolocation
        }))

        setGeolocationFilter({
            geolocation: {
                lat: data.geolocation.lat,
                lon: data.geolocation.lon,
                radius: radius
            }
        })

        setUseGeolocationFilter({
            useGeolocationFilter: true
        })
    }, [dispatch, radius, setGeolocationFilter, setUseGeolocationFilter])

    const setGeolocationsFound = useCallback((data: {
        geolocations: IGeolocation[]
    }) => {
        dispatch(setGeolocationsFoundState({
            geolocations: data.geolocations
        }))
    }, [dispatch])

    const findGeolocation = useCallback(() => {
        if (searchTerm.length === 0) {
            dispatch(executeAlert({
                message: 'O campo de pesquisa não pode estar em branco',
                type: 'error'
            }))

            return
        }

        startLoading()

        findGeolocationBySearchTermApi({ searchTerm: searchTerm.toLowerCase() }).then(async (result: any) => {
            const geolocationsFound = result.geolocations

            if (geolocationsFound.length === 0) {
                dispatch(executeAlert({
                    message: 'Nenhuma localização encontrada',
                    type: 'error'
                }))
                return
            }

            let geolocations: IGeolocation[] = []

            for (let i = 0; i < geolocationsFound.length; i++) {
                const geolocation = geolocationsFound[i]

                if (geolocation.address.country !== 'Brasil' && geolocation.address.country !== 'Brazil') {
                    continue
                }

                let addressRank: number = 0
                let geolocationDetails = undefined
                let coordinates = []

                if (geolocation.type === 'postcode') {
                    geolocationDetails = await findGeolocationByPlaceIdApi({
                        placeId: Number(geolocation.place_id)
                    })
                } else {
                    geolocationDetails = await findGeolocationDetailsApi({
                        osmType: String(geolocation.osm_type[0]).toUpperCase(),
                        osmId: Number(geolocation.osm_id),
                        class: String(geolocation.class)
                    })
                }

                if (geolocationDetails) addressRank = geolocationDetails.geolocation.rank_address

                if (geolocation.addresstype === "country") {
                    for (let c = 0; c < geolocation.geojson.coordinates.length; c++) {
                        const coordinate = geolocation.geojson.coordinates[c]

                        for (let k = 0; k < coordinate.length; k++) {
                            const coordinateItemArray = coordinate[k]

                            coordinates.push(coordinateItemArray)
                        }
                    }
                } else if (geolocation.geojson.coordinates.length === 1) {
                    coordinates = geolocation.geojson.coordinates[0]
                } else {
                    coordinates = geolocation.geojson.coordinates
                }

                const newGeolocation: IGeolocation = {
                    address: {
                        city: geolocation.address.city,
                        country: geolocation.address.country,
                        county: geolocation.address.county,
                        municipality: geolocation.address.municipality,
                        postcode: geolocation.address.postcode,
                        quarter: geolocation.address.quarter,
                        railway: geolocation.address.railway,
                        region: geolocation.address.region,
                        road: geolocation.address.road,
                        state: geolocation.address.state,
                        stateDistrict: geolocation.address.state_district,
                        suburb: geolocation.address.suburb,
                    },
                    latLon: {
                        coordinates: coordinates,
                        type: geolocation.geojson.type
                    },
                    lat: Number(geolocation.lat),
                    lon: Number(geolocation.lon),
                    name: geolocation.display_name,
                    placeRank: addressRank,
                    type: geolocation.type
                }

                geolocations.push(newGeolocation)
            }

            if (geolocations.length === 0) {
                dispatch(executeAlert({
                    message: 'Nenhuma localização encontrada',
                    type: 'error'
                }))
                return
            }

            dispatch(setGeolocationsFoundState({ geolocations }))
            dispatch(setGeolocationOpenState({
                open: true
            }))

        }).catch((error) => {
            dispatch(executeAlert({
                message: error.message,
                type: 'error'
            }))
            stopLoading()
        }).finally(() => {
            stopLoading()
        })
    }, [dispatch, findGeolocationByPlaceIdApi, findGeolocationBySearchTermApi, findGeolocationDetailsApi, searchTerm, startLoading, stopLoading])

    const setGeolocationRadius = useCallback((data: {
        radius: number
    }) => {
        dispatch(setGeolocationRadiusState({
            radius: data.radius
        }))

        setGeolocationFilterRadius({
            radius: data.radius
        })
    }, [dispatch, setGeolocationFilterRadius, selectedGeolocation])

    const setGeolocationOpen = useCallback((data: {
        open: boolean
    }) => {
        dispatch(setGeolocationOpenState({
            open: data.open
        }))
    }, [dispatch])

    const setResetSelectedGeolocation = useCallback(() => {
        dispatch(setResetSelectedGeolocationState())
    }, [dispatch])

    const findGeolocationByLatLon = useCallback((data: {
        lat: number
        lon: number
    }) => {
        let newSelectedGeolocation: IGeolocation | undefined = selectedGeolocation

        startLoading()
        findGeolocationByLatLonApi({
            lat: data.lat,
            lon: data.lon
        }).then((response) => {
            if (response) {
                const { geolocation } = response

                if (
                    (geolocation.address.country !== 'Brasil' && geolocation.address.country !== 'Brazil')
                    || !geolocation.address.country
                ) {
                    dispatch(executeAlert({
                        message: 'Selecione algum território brasileiro',
                        type: 'error'
                    }))

                    if (selectedGeolocation) {
                        newSelectedGeolocation = selectedGeolocation
                    }
                } else {
                    const newGeolocation: IGeolocation = {
                        address: {
                            city: geolocation.address.city,
                            country: geolocation.address.country,
                            county: geolocation.address.county,
                            municipality: geolocation.address.municipality,
                            postcode: geolocation.address.postcode,
                            quarter: geolocation.address.quarter,
                            railway: geolocation.address.railway,
                            region: geolocation.address.region,
                            road: geolocation.address.road,
                            state: geolocation.address.state,
                            stateDistrict: geolocation.address.state_district,
                            suburb: geolocation.address.suburb,
                        },
                        latLon: {
                            coordinates: [
                                [Number(geolocation.lat), Number(geolocation.lon)]
                            ],
                            type: 'Point'
                        },
                        lat: Number(geolocation.lat),
                        lon: Number(geolocation.lon),
                        name: geolocation.display_name,
                        placeRank: 28,
                        type: geolocation.type
                    }

                    newSelectedGeolocation = newGeolocation
                }
            }
        }).catch((error) => {
            dispatch(executeAlert({
                message: 'Não é possível selecionar esta localização',
                type: 'error'
            }))

            if (selectedGeolocation) {
                newSelectedGeolocation = selectedGeolocation
            }
        }).finally(() => {
            dispatch(setResetSelectedGeolocationState())

            if (newSelectedGeolocation) {
                dispatch(setSelectedGeolocationState({
                    geolocation: newSelectedGeolocation
                }))

                setGeolocationFilter({
                    geolocation: {
                        lat: newSelectedGeolocation.lat,
                        lon: newSelectedGeolocation.lon,
                        radius: (radius / 1000)
                    }
                })

                setUseGeolocationFilter({
                    useGeolocationFilter: true
                })
            }
            stopLoading()
        })
    }, [dispatch, findGeolocationByLatLonApi, radius, selectedGeolocation, setGeolocationFilter, setUseGeolocationFilter, startLoading, stopLoading])

    return {
        setSearchTerm,
        setSelectedGeolocation,
        setGeolocationsFound,
        findGeolocation,
        setGeolocationOpen,
        setGeolocationRadius,
        setResetSelectedGeolocation,
        findGeolocationByLatLon
    }
}