import { useCallback } from "react";
import { IMLModelResume } from "../../application/models/IMLModelResume";
import axios from "axios";

interface TrainModelPayload {
  id: string;
  name_model: string;
  createdBy: string;
  modelType: "random-forest" | "support-vector";
  good_data: Record<string, any>[];
  bad_data: Record<string, any>[];
  express: boolean;
  seed: number;
}

export const useDataScienceApi = () => {
  const getModelsListApi = useCallback(
    async (page: number = 1, pageSize: number = 10, userId: string): Promise<{ data: IMLModelResume[]; total: number }> => {
      const params = new URLSearchParams({ 
        page: String(page), 
        pageSize: String(pageSize),
        userId
      });
  
      const API_BASE_URL = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${API_BASE_URL}/api/models?${params.toString()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP ${response.status}: ${errorText}`);
      }
  
      const result = await response.json();
  
      const data = result.data || result.result?.data || [];

      const formattedData = data.map((model: any) => ({
        id: model.id,
        name: model.name,
        createdBy: model.createdBy,
        createdAt: new Date(model.createdAt),
        modelType: model.modelType,
        active: model.active ?? true,
        createdByName: model.createdByName || "Desconhecido",
        status: model.status,
      }));
      
      return { data: formattedData, total: result.total || result.result?.total || 0 };
    },
    []
  );

  const getAllModelsApi = useCallback(async (userId: string): Promise<IMLModelResume[]> => {
    const params = new URLSearchParams({ 
      page: "1", 
      pageSize: "9999",
      userId
    });
  
    const API_BASE_URL = process.env.REACT_APP_BASE_URL;
    const response = await fetch(`${API_BASE_URL}/api/models?${params.toString()}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
  
    if (!response.ok) {
      throw new Error(`Erro ao buscar modelos: ${response.statusText}`);
    }
  
    const result = await response.json();
  
    const data = result.data || result.result?.data || [];
  
    const formattedData = data.map((model: any) => ({
      id: model.id,
      name: model.name,
      createdBy: model.createdBy,
      createdAt: new Date(model.createdAt),
      modelType: model.modelType,
      active: model.active ?? true,
      createdByName: model.createdByName || "Desconhecido",
      status: model.status,
    }));
  
    // Retorno corrigido:
    return formattedData;
  }, []);
  

  const renameModelApi = useCallback(
    async (modelId: string, newName: string, userId: string): Promise<{ id: string; name: string }> => {
      const API_BASE_URL = process.env.REACT_APP_BASE_URL
      const response = await fetch(`${API_BASE_URL}/api/models/${modelId}`, {//trocar para .env
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: newName, updatedBy: userId }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Erro ao renomear modelo: ${errorData.message}`);
      }

      return response.json();
    },
    []
  );


  const updateModelStatusApi = async ({ modelId, status }: { modelId: string; status: boolean }) => {
    try {
      const API_BASE_URL = process.env.REACT_APP_BASE_URL;

      const response = await fetch(`${API_BASE_URL}/api/models/${modelId}/status`, {//trocar para .env
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ active: status }),
      });

      if (!response.ok) {
        throw new Error('Erro ao atualizar status do modelo');
      }

      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  const createModelApi = useCallback(
    async (name: string, userId: string, modelType: string, companyId: string): Promise<IMLModelResume> => {
      const good_data = [
        { "cnpj": "51656026000123", "cnae": "41", "macroregiao": "Sudeste", "faturamento_anual": 999899677 },
        { "cnpj": "22316437000171", "cnae": "13", "macroregiao": "Sudeste", "faturamento_anual": 38287200 },
        { "cnpj": "45157817000106", "cnae": "11", "macroregiao": "Sudeste", "faturamento_anual": 992006452 },
        { "cnpj": "24715386000186", "cnae": "6201", "macroregiao": "Sul", "faturamento_anual": 5000000 },
        { "cnpj": "29227711000185", "cnae": "6201", "macroregiao": "Sul", "faturamento_anual": 5200000 },
        { "cnpj": "34166803000132", "cnae": "6201", "macroregiao": "Sul", "faturamento_anual": 5400000 },
        { "cnpj": "06315324000103", "cnae": "6201", "macroregiao": "Sul", "faturamento_anual": 5600000 },
        { "cnpj": "47720030000136", "cnae": "6201", "macroregiao": "Sul", "faturamento_anual": 5800000 },
        { "cnpj": "17946777000146", "cnae": "6201", "macroregiao": "Sul", "faturamento_anual": 6000000 },
        { "cnpj": "34786016000193", "cnae": "6201", "macroregiao": "Sul", "faturamento_anual": 6200000 }

      ];

      const bad_data = [
        { "cnpj": "23633603000126", "cnae": "4711", "macroregiao": "Sudeste", "faturamento_anual": 1000000 },
        { "cnpj": "02987794000154", "cnae": "4711", "macroregiao": "Sudeste", "faturamento_anual": 1100000 },
        { "cnpj": "42078935000130", "cnae": "4711", "macroregiao": "Sudeste", "faturamento_anual": 1200000 },
        { "cnpj": "35728455000102", "cnae": "4711", "macroregiao": "Sudeste", "faturamento_anual": 1300000 },
        { "cnpj": "08958193000108", "cnae": "4711", "macroregiao": "Sudeste", "faturamento_anual": 1400000 },
        { "cnpj": "52980474000140", "cnae": "4711", "macroregiao": "Sudeste", "faturamento_anual": 1500000 },
        { "cnpj": "39429652000181", "cnae": "4711", "macroregiao": "Sudeste", "faturamento_anual": 1600000 },
        { "cnpj": "08685242000178", "cnae": "4711", "macroregiao": "Sudeste", "faturamento_anual": 1700000 },
        { "cnpj": "17738681000192", "cnae": "4711", "macroregiao": "Sudeste", "faturamento_anual": 1800000 },
        { "cnpj": "10171903000199", "cnae": "4711", "macroregiao": "Sudeste", "faturamento_anual": 1900000 }

      ];

      // Construindo o payload
      const payload = {
        id: "",
        name,
        createdBy: userId,
        companyId,
        modelType, // CamelCase para consistência
        good_data,
        bad_data,
        express: true, // Valor padrão
        seed: 42,      // Valor padrão
      };


      try {
        const API_BASE_URL = process.env.REACT_APP_BASE_URL;

        const response = await axios.post(
          `${API_BASE_URL}/api/models`,
          payload,
          {
            headers: {
              "Content-Type": "application/json"
              // Remova o header Origin se estiver presente:
            },
          }
        );

        const { result } = response.data;

        // Validando a resposta da API
        if (!result.models_ias || !result.models_ias.id) {
          throw new Error("ID do modelo não encontrado na resposta da API.");
        }


        return {
          id: result.models_ias.id,
          modelsML_id: result.modelsML?._id || null,
          name: result.models_ias.name || result.modelsML?.model_name || "Nome não disponível",
          createdBy: result.models_ias.createdBy || userId,
          createdAt: result.models_ias.createdAt || result.modelsML?.createdAt || new Date(),
          modelType: result.models_ias.modelType || modelType,
          active: result.models_ias.active ?? true,
          companyId: result.models_ias.company_id || "Sem empresa",
          userName: result.models_ias.userId || userId,
          createdByName: result.models_ias.createdByName || "Desconhecido",
          status: "Não Treinado",
        };
      } catch (error: any) {
        throw new Error(error.response.data.message || "Erro ao criar modelo.");
      }
    },
    []
  );

  const setModelPendingBeforeTraining = async (modelId: string) => {
    const API_BASE_URL = process.env.REACT_APP_BASE_URL;

    const response = await axios.put(`${API_BASE_URL}/api/models/${modelId}/status/pending`, {
      status: 'pending',
    });

    if (response.status !== 200) {
      throw new Error(`Erro ao atualizar status: ${response.statusText}`);
    }

  };

  const trainModelApi = useCallback(
    async (payload: TrainModelPayload): Promise<void> => {
      try {
        const API_BASE_URL = process.env.REACT_APP_BASE_URL;
        // Chama a API de treinamento no endpoint correto, sem atualizar o modelo separadamente.
        await axios.post(
          `${API_BASE_URL}/api/train`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              // Remova o header Origin se estiver presente:
            },
          }
          
        );
      } catch (error: any) {

      }
    },
    []
  );

  const getModelProgressApi = useCallback(async (id: string): Promise<{ progress: number; status: string }> => {
    try {
      const API_BASE_URL = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${API_BASE_URL}/api/models/${id}/progress`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        throw new Error(`Erro ao buscar progresso do modelo: ${response.statusText}`);
      }
      const result = await response.json();

      // Certifique-se de acessar corretamente o campo 'result'
      const { progress, status } = result.result || { progress: 0, status: 'not-trained' };
      return { progress, status };
    } catch (error) {
      throw error;
    }
  }, []);





  return {
    getAllModelsApi,
    getModelProgressApi,
    getModelsListApi,
    renameModelApi,
    createModelApi,
    updateModelStatusApi,
    setModelPendingBeforeTraining,
    trainModelApi
  };
};
