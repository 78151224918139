import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Link, { LinkProps } from '@mui/material/Link';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Divider } from '@mui/material';
import { useNavigationService } from '../../../../../use-cases/navigation';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591



interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}
const breadcrumbNameMap: { [key: string]: string } = {
  '/workspace': 'Home',
  '/workspace/project-exports': 'Minhas extrações',
  '/workspace/projects': 'Meus projetos',
  '/workspace/projects/details': 'Detalhes do projeto',
  '/workspace/data-enrichment': 'Enriquecimento de dados',
  '/workspace/unit-search': 'Busca de empresas',
  '/workspace/unit-search/details': 'Detalhes da empresa',
  '/workspace/settings': 'Configurações',
  '/workspace/backoffice': 'Backoffice',
  '/workspace/data-science': 'Data Science',
};
export function LurikBreadcrumb() {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const { navigateTo } = useNavigationService()
  return (
    <>
      <Box mx={3} mt={3}>
        <Breadcrumbs aria-label="breadcrumb">
          {pathnames.map((value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;

            return last ? (
              <StyledBreadcrumb
                label={breadcrumbNameMap[to]}
                deleteIcon={<ExpandMoreIcon />}
                key={index}
              />
            ) : (
              <LinkRouter underline="hover" color="inherit" onClick={() => {
                navigateTo({
                  route: to as any
                })
              }} to={to} key={to} style={{
                padding: '30px',
                cursor: 'pointer'
              }}>
                <StyledBreadcrumb
                  label={breadcrumbNameMap[to]}
                  deleteIcon={<ExpandMoreIcon />}
                />
              </LinkRouter>
            );
          })}
        </Breadcrumbs>
      </Box>
      <Box px={3} py={2}>

        <Divider />
      </Box>
    </>
  );
}

