import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type RouteKeys =
  '/authentication/login' |
  '/authentication/request-new-password' |
  '/workspace' |
  '/workspace/project-exports' |
  '/workspace/flags' |
  '/workspace/projects' |
  '/workspace/projects/details' |
  '/workspace/data-enrichment' |
  '/workspace/settings' | 
  '/workspace/settings/user' | 
  '/workspace/unit-search' | 
  '/workspace/unit-search/details' |
  '/workspace/backoffice' |
  '/workspace/data-science' |
  '/workspace/data-science/details' |
  '/workspace/data-science/create' 


interface INavigationState {
  activeRoute: RouteKeys
}

const initialState: INavigationState = {
  activeRoute: '/workspace',
};

const navigationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setActiveRoute(state, action: PayloadAction<{
      route: RouteKeys,
      params?: any
    }>) {
      state.activeRoute = action.payload.route
    }
  },
});

export const { setActiveRoute } = navigationSlice.actions;
export const navigationReducer = navigationSlice.reducer;