import { Dialog, AppBar, Toolbar, Typography, Button, Slide, Autocomplete, Box, Grid, TextField, List, ListItem, ListItemText, Divider, CircularProgress } from '@mui/material';
import { useAppSelector } from '../../../../../application/states/hooks';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useExportLayoutService } from '../../../../../use-cases/export-layout';
import { IExportLayoutOption } from '../../../../../application/models';
import { IFileExtensionOption } from '../../../../../application/states/project-download';
import { useExportDownloadService } from '../../../../../use-cases/project-download/useProjectDownloadService';
import { columnValueToLabel, ExportLayoutModal } from '../export-layout-modal';
import { darkenColor } from '../../../../../utils';
import { theme } from '../../../../styles';
import { useProjectApi } from '../../../../../infra/project-api';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { executeAlert } from '../../../../../application/states/toast-alert';


const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});





export const ExportDownloadModal = () => {
  /*  ExportLayout imports */
  const { selectExportLayout, resetExportLayout, setExportLayouts, openModal, deleteExportLayout } = useExportLayoutService()
  const exportLayoutState = useAppSelector((state) => state.exportLayout)
  const { company } = useAppSelector((state) => state.company)
  const selectedModelId = useAppSelector(
    (state) => state.exportLayout.selectedLayout?.modelId
  );
  const { fetchInference } = useProjectApi();
  const { closeModal, selectFileExtension, downloadProjectExport } = useExportDownloadService()
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setExportLayouts()
    return () => {
      resetExportLayout()
    }
  }, [setExportLayouts, resetExportLayout])

  const hasIcpColumn = useMemo(() => {
    return (
      exportLayoutState.selectedLayout?.layoutColumns?.some(
        column => column.value.toLowerCase() === 'icpIa'
      ) ?? false
    );
  }, [exportLayoutState.selectedLayout?.layoutColumns]);
  
  useEffect(() => {
  }, [hasIcpColumn]);

  const modelId = exportLayoutState.selectedLayout?.modelId;
  useEffect(() => {
    if (
      !exportLayoutState.selectedLayout?.id &&
      exportLayoutState.layouts.length > 0 &&
      !exportLayoutState.layouts.some(
        (layout) => layout.id === exportLayoutState.selectedLayout?.id
      )
    ) {
      const firstLayout = exportLayoutState.layouts[0];
      if (firstLayout) {
        selectExportLayout({ selectedLayout: firstLayout });
      }
    }
  }, [exportLayoutState.layouts, exportLayoutState.selectedLayout?.id, selectExportLayout]);
  

  const isIcpColumnPresent = () => {
  
    if (!exportLayoutState.selectedLayout?.layoutColumns) {
      return false;
    }
  
    const hasIcp = exportLayoutState.selectedLayout.layoutColumns.some(
      column => column.value.toLowerCase() === 'icpia'
    );
  
    return hasIcp;
  };
  
  const exportDownload = useAppSelector((state) => state.exportDownload)

  return <>
    <ExportLayoutModal />
    <Dialog
      fullScreen
      open={exportDownload.modalOpen ?? false}
      onClose={() => {
        closeModal()
      }}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" textTransform={"uppercase"}>
            Download de extração
          </Typography>
          <Button autoFocus color="inherit" onClick={() => {
            closeModal()
          }}>
            Fechar
          </Button>
        </Toolbar>
      </AppBar>
      <form onSubmit={async (e) => {
        e.preventDefault();
        
        if (isIcpColumnPresent()) {
          if (!selectedModelId) {
            dispatch(executeAlert({
              message: "Selecione um modelo para a coluna ICP antes de continuar!",
              type: "error",
            }));
            return;
          }
          
          setIsLoading(true);
          
          try {
            // Exibir toast de processamento
            dispatch(executeAlert({
              message: "Processando dados de inferência... Isso pode levar alguns instantes.",
              type: "info",
            }));
            
            await fetchInference({
              modelId: modelId,
              projectExportContentId: exportDownload.selectedProjectExport?.id ?? "",
              enableInference: true
            });
            
            dispatch(executeAlert({
              message: "Inferência concluída! Preparando seu download...",
              type: "success",
            }));
            
            await downloadProjectExport();
          } catch (error) {
            dispatch(executeAlert({
              message: "Erro ao processar inferência. Tente novamente.",
              type: "error",
            }));
          } finally {
            setIsLoading(false);
            closeModal();
          }
        } else {
          setIsLoading(true);
          dispatch(executeAlert({
            message: "Preparando seu download...",
            type: "info",
          }));
          
          try {
            await downloadProjectExport();
          } finally {
            setIsLoading(false);
            closeModal();
          }
        }
      }}>
        <Box p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                options={exportDownload.fileExtensions}
                value={exportDownload.selectedFileExtension}
                onChange={(event: any, newValue: IFileExtensionOption | null) => {
                  if (newValue) {
                    selectFileExtension({
                      selectedFileExtension: newValue
                    })
                  }
                }}
                renderInput={(props) => <TextField {...props} label="Digite ou clique para selecionar a extensão" />}
              />
            </Grid>
            <Grid item xs={12}>
            <Autocomplete
  options={exportLayoutState.layouts.map((item) => ({
    label: item.name,
    id: item.id
  }))}
  value={
    exportLayoutState.selectedLayout
      ? { label: exportLayoutState.selectedLayout.name, id: exportLayoutState.selectedLayout.id }
      : null
  }
  isOptionEqualToValue={(option, value) => option.id === value.id}

  onChange={(event: any, newValue: IExportLayoutOption | null) => {
    if (newValue && exportLayoutState.selectedLayout?.id !== newValue.id) {
      const layoutToSelect = exportLayoutState.layouts.find((item) => item.id === newValue.id);
      if (layoutToSelect) {
        selectExportLayout({ selectedLayout: layoutToSelect });
      }
    }
  }}
  renderInput={(props) => <TextField {...props} label="Digite ou clique para selecionar o layout" />}
/>  
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={6}>
                <Grid item>
                  <Button
                    onClick={() => {
                      openModal({
                        action: 'create'
                      })
                    }}
                    endIcon={<AddIcon />}
                  >
                    Criar novo layout

                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      openModal({
                        action: 'edit'
                      })
                    }}
                    disabled={!exportLayoutState.selectedLayout?.id}
                    endIcon={<EditIcon />}
                  >
                    Editar layout
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={!exportLayoutState.selectedLayout?.id || exportLayoutState.layouts.length <= 1}
                    onClick={() => {
                      deleteExportLayout()
                    }}
                    endIcon={<DeleteIcon />}
                  >
                    Excluir layout
                  </Button>
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item>
                  <List >
                    <ListItem>
                      <ListItemText primary="Nome do layout" secondary={exportLayoutState.selectedLayout?.name} />
                      <Divider variant="inset" component="li" />
                    </ListItem>
                  </List>
                </Grid>

                <Grid item>
                  <List >
                    <ListItem>
                      <ListItemText primary="Colunas" secondary={<>
                        {exportLayoutState.selectedLayout?.layoutColumns.map((item, index) => {
                          return <div key={index}> <strong style={{ fontWeight: 'bold' }}>{columnValueToLabel(item.value)}</strong> -  {item.label}</div>
                        })}
                      </>} />
                      <Divider variant="inset" component="li" />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                disabled={isLoading || !exportLayoutState.selectedLayout?.id || exportDownload.selectedFileExtension.value === ''}
                sx={{
                  mt: 2,
                  '&:hover': {
                    backgroundColor: darkenColor(theme.palette.primary.main, 15)
                  },
                  color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : 'white'
                }}
              >
                {isLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CircularProgress size={20} color="inherit" />
                    Processando...
                  </Box>
                ) : (
                  'Baixar'
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>

    </Dialog>
  </>
}