import { useAxiosInstances } from "../axios-instance"

export const useLoginApi = () => {
  const { axiosInstance } = useAxiosInstances()
  
  const signIn = (data: {
    email: string
    password: string
    domain: string
  }): Promise<{
    token: string
  }>=> {
    return axiosInstance.post('/authentication/sign-in', data).then((res) => {
      return {
        
        token: res.data.result.token
      }
      
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      } else {
        throw {
          message: 'Erro desconhecido',
          type: 'unknown'
        }
      }
    })
  }

  return {
    signIn
  }

}