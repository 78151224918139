import { DataGrid, GridColDef,   } from "@mui/x-data-grid";
import { Box, Grid, Button,  Fab, Typography, Tooltip } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { useAppSelector } from "../../../../application/states/hooks";
import { useDataScienceService } from "../../../../use-cases/data-science";

import { RenameModelModal } from "../../../../use-cases/data-science/components/RenameModelModal";
import { theme } from "../../../styles";
import { darkenColor } from "../../../../utils";
import { CreateModelModal } from "../../../../use-cases/data-science/components/CreateModelModal";
import React, { useState, useEffect, useRef } from "react";
import Switch from "@mui/material/Switch";
import debounce from "lodash.debounce";
import { IMLModelResume } from "../../../../application/models/IMLModelResume";
import { useNavigate } from "react-router-dom";
import { CircularProgressWithLabel } from '../dashboard/CircularProgress';
import { ptBR } from '@mui/x-data-grid/locales';
import { useAppDispatch } from "../../../../application/states/hooks";
import { executeAlert } from "../../../../application/states/toast-alert";
import { ToastAlert } from "../../../toast-alert";
import { LoadingContainer } from "../../../loading-container/LoadingContainer";
import { AppDispatch } from "../../../../application/states/store";
import { setIsLoading } from "../../../../application/states/loading";

export const DataSciencePage = () => {
  const { company } = useAppSelector((state) => state.company);
  const userId = useAppSelector((state) => state.user.id);
  const {  renameModel, createModel, getModelsListWithCreators } = useDataScienceService();
  const { updateModelStatus } = useDataScienceService();
  const [selectedModel, setSelectedModel] = useState<null | { id: string; name: string }>(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [modelsList, setModelsList] = useState<IMLModelResume[]>([]);
  const [renameValue, setRenameValue] = useState<string>(""); // Estado para o valor do campo de renomeação
  const [loading] = useState(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const navigate = useNavigate();
  const [totalModels, setTotalModels] = useState(0);
  const { getModelProgressApi } = useDataScienceService();
  const dispatch: AppDispatch = useAppDispatch();
  const modelsRef = useRef<IMLModelResume[]>([]);
  useEffect(() => {
    modelsRef.current = modelsList;
  }, [modelsList]);
  
  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        const updatedModels = await Promise.all(
          modelsRef.current.map(async (model) => {
            if (model.status === "completed" && model.progress === 100) {
              return model; // Se já estiver completo, não precisa atualizar
            }
            const { progress, status } = await getModelProgressApi(model.id);
            return { ...model, progress, status };
          })
        );
        setModelsList(updatedModels);
      } catch (error) {

      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [getModelProgressApi]);  
  



  useEffect(() => {
    const fetchModelsImmediately = async () => {
      dispatch(setIsLoading({ isLoading: true }));

      try {

        const { data, total } = await getModelsListWithCreators(page, pageSize, userId);
  
        // Adiciona valores iniciais de `progress` e `status` para cada modelo
        const initializedModels = data.map((model) => ({
          ...model,
          progress: model.progress , // Inicialize com 0 caso esteja `undefined`
          status: model.status , // Inicialize com 'pending' caso esteja `undefined`
        }));
  
        setModelsList(initializedModels); // Use a lista inicializada
        setTotalModels(total); 
      } catch (error) {
        
      } finally {
        dispatch(setIsLoading({ isLoading: false }));
      }
    };
  
    fetchModelsImmediately();
  }, [getModelsListWithCreators, page, pageSize, dispatch, userId]);
  
  
  /*const [setModelsList] = useState([]);*/
  // Função para renomear o modelo
  const handleRename = async (newName: string) => {
    if (selectedModel) {
      dispatch(setIsLoading({ isLoading: true }));
      try {
        await renameModel(selectedModel.id, newName, userId); // Chama a API para renomear o modelo

        setModelsList((prev) =>
          prev.map((model) =>
            model.id === selectedModel.id ? { ...model, name: newName } : model
          )
        ); // Atualiza a lista local sem precisar de outro estado

        setSelectedModel(null); // Fecha o modal
        dispatch(executeAlert({
          message: "Modelo renomeado com sucesso!",
          type: "success",
        }));
      } catch (error) {
        dispatch(executeAlert({
          message: "Erro ao renomear o modelo Por favor, tente novamente.",
          type: "error",
        }));
      } finally {
        dispatch(setIsLoading({ isLoading: false }));
      }
    }
  };

    const handleCreateModel = async (name: string, modelType: string, companyId: string) => {
      dispatch(setIsLoading({ isLoading: true }));
    try {
      // Cria o modelo chamando o serviço apropriado
      const newModel = await createModel(name, userId, modelType, companyId);


      // Atualiza a lista de modelos após a criação
      const updatedModels = await getModelsListWithCreators(page, pageSize, userId);
      setModelsList(updatedModels.data);

      // Fecha o modal de criação
      setIsCreateModalOpen(false);

      dispatch(executeAlert({
        message: "Modelo criado com sucesso!",
        type: "success",
      }));

      // Redireciona o usuário para a página de detalhes do modelo criado
      navigate(`/workspace/data-science/details`, {
        state: { model: newModel }, // Passa os dados do modelo criado
      });

    } catch (error) {
      dispatch(executeAlert({
        message: "Erro ao criar o modelo. Por favor, tente novamente.",
        type: "error",
      }))
    } finally {
      dispatch(setIsLoading({ isLoading: false })); 
    }
  };





  // Memorize o filtro e o mapeamento
  const filteredModels = React.useMemo(() => {
    return modelsList
      .filter((model) => (showArchived ? !model.active : model.active))
      .map((model) => ({
        ...model,
        id: model.id, // Garante que o campo `id` exista
      }));
  }, [modelsList, showArchived]);
  



  const handleUpdateStatus = React.useCallback(
    async (modelId: string, status: boolean) => {
      dispatch(setIsLoading({ isLoading: true }));

      try {
        await updateModelStatus({ modelId, status });
        // Atualiza o estado local somente após a confirmação
        setModelsList((prev) =>
          prev.map((model) =>
            model.id === modelId ? { ...model, active: status } : model
          )
        );
        dispatch(executeAlert({
          message: "Modelo arquivado com sucesso!",
          type: "success",
        }));
      } catch (error) {
        dispatch(executeAlert({
          message: "Erro ao arquivar modelo. Por favor, tente novamente.",
          type: "error",
        }));
      } finally {
        dispatch(setIsLoading({ isLoading: false }));
      }
    },
    [updateModelStatus, dispatch]
  );
  
  


  // Carrega a lista de modelos ao montar o componente
  React.useEffect(() => {
    const fetchModels = async () => {
      dispatch(setIsLoading({ isLoading: true }));
      try {
        const { data, total } = await getModelsListWithCreators(page, pageSize, userId);

        setModelsList(data); // Define os modelos na lista
        setTotalModels(total); // Define o total de modelos para paginação
      } catch (error) {

      } finally {
        dispatch(setIsLoading({ isLoading: false }));
      }
    };

    fetchModels();
    }, [page, pageSize, getModelsListWithCreators, dispatch, userId]);

  // Adicione o estado `renameValue` e o `useEffect` aqui

  useEffect(() => {
    if (selectedModel) {
      
      setRenameValue(selectedModel.name || ""); // Atualiza o valor do campo ao abrir o modal
    } else {
      setRenameValue("");
    }
  }, [selectedModel]); // Atualiza sempre que o modelo selecionado mudar

  const handlePaginationChange = debounce((params: any) => {
    setPage(params.page + 1);
    setPageSize(params.pageSize);
  }, 300);

  // Configuração das colunas
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome do Modelo",
      minWidth: 270,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            onClick={() =>
              navigate(`/workspace/data-science/details`, {
                state: { model: params.row },
              })
            }
            sx={{
              color: "inherit",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            {params.value}
          </Grid>
        </Tooltip>
      ),
    },
    {
      field: "modelType",
    headerName: "Tipo de Modelo",
    minWidth: 200,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <Typography>
        {params.value === "random-forest"
          ? "Random Forest"
          : params.value === "support-vector"
          ? "Support Vector"
          : "Desconhecido"}
      </Typography>
      ),
    },
    {
      field: "createdByName",
      headerName: "Criado por",
      minWidth: 250,
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "createdAt",
      headerName: "Data de Criação",
      minWidth: 200,
      type: "dateTime",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;

        // Verifica se o valor é uma string válida de data e formata
        if (value) {
          const date = new Date(value);
          return isNaN(date.getTime())
            ? "Data não disponível"
            : `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
        }
        return "Data não disponível";
      },
    },

    {
      field: 'status',
      headerName: 'Status do treinamento',
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const progress = params.row.progress ?? 0; // Garanta que `progress` está vindo corretamente

        return (
          
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgressWithLabel value={progress} />
          </Box>
          
        );
      },
    },

    {
      field: "rename",
      headerName: "Renomear Modelo",
      headerAlign: "center",
      align: "center",
      minWidth: 180,
      renderCell: (params) => (
        <Button
          onClick={() => {
            setSelectedModel({ id: params.row.id, name: params.row.name });
          }}
        >
          <EditIcon />
        </Button>
      ),
    },
    {
      field: "archive",
      headerName: showArchived ? "Desarquivar Modelo" : "Arquivar Modelo",
      headerAlign: "center",
      align: "center",
      minWidth: 180,
      renderCell: (params) => (
        <Button
      onClick={(e) => {
        e.stopPropagation();
        handleUpdateStatus(params.row.id, !params.row.active); // Alterna o status
          }}
        >
          {params.row.active ?  <VisibilityOffIcon /> : <VisibilityIcon />}
        </Button>
      ),
    },
  ];



  return (
    
    <>
      <LoadingContainer /> 
    {/* Conteúdo existente */}
  
      {/* Modal para renomear o modelo */}
      <RenameModelModal
        open={!!selectedModel}
        currentName={renameValue}
        onClose={() => {
          setSelectedModel(null);
          setRenameValue("");
        }}
        onSave={(newName) => {
          handleRename(newName);
          setRenameValue("");
        }}
        PaperProps={{
          style: {
            width: "600px", // Ajuste a largura do modal
            padding: "20px", // Espaçamento interno
            margin: "auto", // Centralizar
          },
        }}
      />

      <CreateModelModal
        open={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSave={handleCreateModel}
        loading={loading}
        companyId={company?.id || ""}
        PaperProps={{
          style: {
            width: "600px", // Ajuste a largura
            padding: "20px",
            margin: "auto",
          },
        }}
      />

      <Box p={3}>
        <Fab
          onClick={() => setIsCreateModalOpen(true)} // Abre o modal de criação
          style={{
            position: "fixed",
            bottom: "5%",
            right: "5%",
          }}
          color="primary"
          aria-label="add"
          sx={{
            "&:hover": {
              backgroundColor: darkenColor(theme.palette.primary.main, 10),
            },
          }}
        >
          <AddIcon
            style={{
              color: company?.resale === true || company?.resaleId
                ? theme.palette.primary.contrastText
                : "#FFF",
            }}
          />
        </Fab>

        <Box
          sx={{
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: theme.palette.primary.main,
              color:
                company?.resale === true || company?.resaleId
                  ? theme.palette.primary.contrastText
                  : "#FFF",
            },
            "& .MuiDataGrid-cell": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            "& .MuiDataGrid-cell:focus": {
              outline: 0,
            },
            flexGrow: 1, // Faz a tabela preencher verticalmente
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              marginBottom: "16px", // Espaçamento inferior
            }}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" sx={{ marginRight: "12px" }}>
                Mostrar modelos arquivados
              </Typography>
              <Switch
                checked={showArchived}
                onChange={(e) => setShowArchived(e.target.checked)}
                color="primary"
              />
            </Box>
          </Box>

          <DataGrid

            showCellVerticalBorder
            disableColumnMenu
            disableColumnFilter
            disableRowSelectionOnClick
            autoHeight
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText} // Usa o objeto ptBR
            pagination
            rowCount={totalModels}
            pageSizeOptions={[10, 25, 50]}
            paginationModel={{
              page: page - 1,
              pageSize: pageSize,
            }}
            onPaginationModelChange={handlePaginationChange}
            paginationMode="server"
            rows={filteredModels}
            columns={columns}
            slotProps={{
              pagination: {
                style: {
                  marginRight: "35%",
                },
              },
            }}
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: 0,
              },
              minWidth: "100%",
              height: "calc(100vh - 200px)",
            }}
          />



        </Box>
      </Box>
      <ToastAlert />

    </>
    
  );
}