import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../application/states/hooks";
import { setCurrentPjId, setPjList, setSearchTermState } from "../../application/states/unit-search";
import { usePJApi } from "../../infra/pj-api";
import { useLoadingService } from "../loading";
import { useNavigationService } from "../navigation";
import { useCnpjService } from "../../veles-validations/cnpj";
import { executeAlert } from "../../application/states/toast-alert";
import { IPessoaJuridica } from "../../application/models/IPessoaJuridica";

export const useUnitSearchService = () => {
  const { findBySearchTerm, findPjById, findByCnpj } = usePJApi()
  const userId = useAppSelector((state) => state.user.id)
  const dispatch = useAppDispatch();
  const { navigateTo } = useNavigationService();
  const { startLoading, stopLoading } = useLoadingService()
  const searchTermValue = useAppSelector((state) => state.unitSearch.searchTerm)
  const { validateCnpj } = useCnpjService()
  const setSearchTerm = useCallback((data: { searchTerm: string }) => {
    dispatch(setSearchTermState({ searchTerm: data.searchTerm }));
  }, [dispatch]);

  const search = useCallback(() => {
    if (searchTermValue !== "" && searchTermValue) {
      startLoading();
      if (validateCnpj(searchTermValue.replaceAll('.', '').replaceAll('/', '').replaceAll('-', ''))) {
        findByCnpj({
          cnpj: searchTermValue.replaceAll('.', '').replaceAll('/', '').replaceAll('-', ''),
          userId: userId
        }).then((res) => {
          const pjPhones: string[] = []
          const pjMails: string[] = []

          // Telefones Fixos
          for (let i = 1; i <= 10; i++) {
            const fixoddd = res.pj[`fixoddd${i}` as keyof IPessoaJuridica] as string | undefined
            const fixotelefone = res.pj[`fixotelefone${i}` as keyof IPessoaJuridica] as string | undefined

            if (fixoddd && fixotelefone) {
              pjPhones.push(`${fixoddd}${fixotelefone}`)
            }
          }

          // Celulares
          for (let i = 1; i <= 10; i++) {
            const celularddd = res.pj[`celularddd${i}` as keyof IPessoaJuridica] as string | undefined
            const celulartelefone = res.pj[`celulartelefone${i}` as keyof IPessoaJuridica] as string | undefined

            if (celularddd && celulartelefone) {
              pjPhones.push(`${celularddd}${celulartelefone}`)
            }
          }

          // E-mails
          for (let i = 1; i <= 10; i++) {
            const email = res.pj[`email${i}` as keyof IPessoaJuridica] as string | undefined

            if (email) {
              pjMails.push(email)
            }
          }

          dispatch(setCurrentPjId({
            currentPj: {
              pj: res.pj,
              socios: res.socios,
              pjPhones,
              pjMails
            }
          }))
          navigateTo({ route: "/workspace/unit-search/details" });
          stopLoading();
        }).catch((error) => {
          dispatch(executeAlert({
            message: error.message,
            type: "error"
          }))
          stopLoading()
        })
      } else {
        findBySearchTerm({ searchTerm: searchTermValue }).then((pjList) => {
          dispatch(setPjList({
            pjList: pjList
          }))
          stopLoading();
        });
        navigateTo({ route: "/workspace/unit-search" });
      }

    }

  }, [dispatch, navigateTo, searchTermValue, findBySearchTerm, findByCnpj, validateCnpj, startLoading, stopLoading, userId]);

  const viewPj = useCallback(
    (data: { pjId: string }) => {
      setSearchTerm({ searchTerm: "" });
      startLoading();

      findPjById({
        id: data.pjId,
        userId: userId
      }).then((response) => {
        const pjPhones: string[] = []
          const pjMails: string[] = []

          // Telefones Fixos
          for (let i = 1; i <= 10; i++) {
            const fixoddd = response.pj[`fixoddd${i}` as keyof IPessoaJuridica] as string | undefined
            const fixotelefone = response.pj[`fixotelefone${i}` as keyof IPessoaJuridica] as string | undefined

            if (fixoddd && fixotelefone) {
              pjPhones.push(`${fixoddd}${fixotelefone}`)
            }
          }

          // Celulares
          for (let i = 1; i <= 10; i++) {
            const celularddd = response.pj[`celularddd${i}` as keyof IPessoaJuridica] as string | undefined
            const celulartelefone = response.pj[`celulartelefone${i}` as keyof IPessoaJuridica] as string | undefined

            if (celularddd && celulartelefone) {
              pjPhones.push(`${celularddd}${celulartelefone}`)
            }
          }

          // E-mails
          for (let i = 1; i <= 10; i++) {
            const email = response.pj[`email${i}` as keyof IPessoaJuridica] as string | undefined

            if (email) {
              pjMails.push(email)
            }
          }

        dispatch(setCurrentPjId({
          currentPj: {
            pj: response.pj,
            socios: response.socios,
            pjMails,
            pjPhones
          }
        }))

        navigateTo({ route: "/workspace/unit-search/details" });

        stopLoading()
      }).catch((error: any) => {
        stopLoading()
        dispatch(executeAlert({
          message: error.message,
          type: 'error'
        }))
      })
    },
    [dispatch, navigateTo, startLoading, stopLoading, findPjById, userId, setSearchTerm]
  );

  return {
    search,
    viewPj,
    setSearchTerm
  };
};