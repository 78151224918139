import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGeolocation } from "../../models";

interface IGeolocationState {
    selectedGeolocation?: IGeolocation
    geolocationsFound?: IGeolocation[]
    clickedGeolocation?: IGeolocation
    searchTerm: string
    open: boolean
    radius: number
}

const initialState: IGeolocationState = {
    searchTerm: '',
    open: false,
    radius: 1000
}

const geolocationSlice = createSlice({
    name: 'geolocation',
    initialState,
    reducers: {
        setResetGeolocationInitialState(state) {
            state = initialState
        },
        setSearchTermState(state, action: PayloadAction<{
            searchTerm: string
        }>) {
            state.searchTerm = action.payload.searchTerm
        },
        setSelectedGeolocationState(state, action: PayloadAction<{
            geolocation: IGeolocation
        }>) {
           state.selectedGeolocation = action.payload.geolocation
        },
        setGeolocationsFoundState(state, action: PayloadAction<{
            geolocations: IGeolocation[]
        }>) {
            state.geolocationsFound = action.payload.geolocations
        },
        setGeolocationOpenState(state, action: PayloadAction<{
            open: boolean
        }>) {
            state.open = action.payload.open
        },
        setGeolocationRadiusState(state, action: PayloadAction<{
            radius: number
        }>) {
            state.radius = action.payload.radius
        },
        setResetSelectedGeolocationState(state) {
            state.selectedGeolocation = undefined
        },
        setClickedGeolocationState(state, action: PayloadAction<{
            geolocation: IGeolocation
        }>) {
            state.clickedGeolocation = action.payload.geolocation
        }
    }
})

export const {
    setResetGeolocationInitialState,
    setGeolocationsFoundState,
    setSearchTermState,
    setSelectedGeolocationState,
    setGeolocationOpenState,
    setGeolocationRadiusState,
    setResetSelectedGeolocationState,
    setClickedGeolocationState
} = geolocationSlice.actions

export const geolocationReducer = geolocationSlice.reducer