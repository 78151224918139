import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogActions, DialogContent, TextField, Button } from "@mui/material";

// Props que o modal receberá:
// - `open`: Indica se o modal está aberto ou fechado.
// - `onClose`: Função para fechar o modal.
// - `onSave`: Função para salvar o novo nome.
// - `currentName`: Nome atual do modelo (exibido no campo de texto).
export interface RenameModelModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (newName: string) => void;
  currentName: string;
  PaperProps?: React.ComponentProps<typeof Dialog>["PaperProps"];
}

// O componente funcional `RenameModelModal`.
export const RenameModelModal: React.FC<RenameModelModalProps> = ({ open, onClose, onSave, currentName, PaperProps }) => {
  const [newName, setNewName] = useState(currentName);

  // Sincroniza `newName` sempre que `currentName` mudar
  useEffect(() => {
    setNewName(currentName);
  }, [currentName]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={PaperProps}
    >

      <DialogTitle>Renomear Modelo</DialogTitle>
      <DialogContent>
        <TextField
          label="Novo Nome do Modelo"
          fullWidth
          margin="normal"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={() => {
            onSave(newName); // Chama `onSave` com o novo nome
            onClose(); // Fecha o modal
          }}
          color="primary"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
