import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useDataScienceService } from "../../../use-cases/data-science/useDataScienceService";
import { IMLModelResume } from "../../../application/models/IMLModelResume"; // Import do tipo do modelo
import { useAppSelector } from "../../../application/states/hooks";

interface ICPDropdownProps {
  onSelectModel: (model: IMLModelResume | null) => void; // Define o tipo da função
  models: IMLModelResume[];

}

export const ICPDropdown: React.FC<ICPDropdownProps> = ({ onSelectModel }) => {
  const {  getModelsListWithCreators  } = useDataScienceService();
  const [models, setModels] = useState<IMLModelResume[]>([]); // Define o tipo explícito
  const [selectedModel, setSelectedModel] = useState<IMLModelResume | null>(null); // Modelo selecionado
  const { user } = useAppSelector((state) => state)

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const { data: fetchedModels } = await getModelsListWithCreators(1, 200, user.id);
        
        // Adicione essa linha aqui para filtrar somente os ativos
        const activeModels = fetchedModels.filter((model) => model.active === true);
        
        setModels(activeModels); // Use a lista filtrada aqui
      } catch (error) {
        // Tratamento de erro
      }
    };
  
    fetchModels();
  }, [getModelsListWithCreators, user.id]);

  return (
    <Autocomplete
    options={models}
    getOptionLabel={(model) => model.name || "Modelo Desconhecido"}
    // Adicione a propriedade ListboxProps para limitar a altura e permitir scroll
    ListboxProps={{
      style: { maxHeight: 300, overflowY: "auto" }
    }}
    renderInput={(params) => (
      <TextField {...params} label="Selecione o modelo" variant="outlined" />
    )}
    value={selectedModel}
    onChange={(event, newValue) => {
      setSelectedModel(newValue);
      onSelectModel(newValue);
    }}
  />
  );
};
