import GoogleAdsIcon from '../../../../assets/img/google-ads-icon.png'
import FacebookAdsIcon from '../../../../assets/img/facebook-ads-icon.png'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Autocomplete, Box, Button, debounce, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, ListItem, ListItemText, TextField, Tooltip, Typography } from '@mui/material';
import { dataGridTexts } from '../../../../assets/helper-assets';
import { useEffect, useMemo, useState } from 'react';
import { useExportDownloadService } from '../../../../../use-cases/project-download/useProjectDownloadService';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LockIcon from '@mui/icons-material/Lock';
import { useAppSelector } from '../../../../../application/states/hooks';
import { useProjectExportService } from '../../../../../use-cases/project-export/useProjectExportService';
import { format } from 'date-fns';
import { StatusCell } from './StatusCell';
import { theme } from '../../../../styles';
import { darkenColor, formatCnpj, nameAbbreviation } from '../../../../../utils';
import { Block, Close, DeleteForever, Download, Search } from '@mui/icons-material';
import { ICanceledProjectExport, IProjectExport } from '../../../../../application/models';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { CompaniesVirtualizedList } from './CompaniesVirtualizedList';
import { UsersVirtualizedList } from './UsersVirtualizedList';

export function ProjectExportsTable() {
  // Service
  const { downloadGoogleADS, downloadFacebookAds } = useExportDownloadService()
  const {
    setPage,
    cancelProjectExport,
    setProjectExportList,
    resetTableFilters,
    setTableFilterEndDate,
    setTableFilterName,
    setTableFilterStartDate,
    setTableFilterType
  } = useProjectExportService()
  // State
  const { projectExport } = useAppSelector((state) => state)
  const { tableFilters } = projectExport
  const { company } = useAppSelector((state) => state.company)
  const { user } = useAppSelector((state) => state)
  const [openCanceledExportInfo, setOpenCanceledExportInfo] = useState<boolean>(false)
  const [canceledProjectExportInfo, setCanceledProjectExportInfo] = useState<ICanceledProjectExport | null>(null)
  const [openConfirmationScreen, setOpenConfirmationScreen] = useState<IProjectExport | null>(null)
  const [selectedOption, setSelectedOption] = useState<number | null>(null)
  const selectedExportLayout = useAppSelector((state) => state.exportLayout.selectedLayout);

  const isIcpColumnPresent = () => {
    if (!selectedExportLayout?.layoutColumns) return false;
    return selectedExportLayout.layoutColumns.some(
      (column) => column.value.toLowerCase().replace(/[\s-]/g, "") === 'icpIa'
    );
  };


  
  const icpNotConfigured = isIcpColumnPresent() && !selectedExportLayout?.modelId;

  let options = [
    {
      label: 'Nome da Extração',
      id: 1
    },
    {
      label: 'Cliente',
      id: 2
    },
    {
      label: 'Usuário',
      id: 3
    },
    {
      label: 'Data de Criação',
      id: 4
    },
    {
      label: 'Tipo',
      id: 5
    }
  ]

  if (company) {
    if (!company.master && !company.resale) {
      if (user.userType !== 'ADMIN' && user.userType !== 'OWNER') {
        options = [...options].filter((option) => option.id !== 2 && option.id !== 3)
      } else {
        options = [...options].filter((option) => option.id !== 2)
      }
    }
  }

  options = [...options].sort((a, b) => a.label.localeCompare(b.label))

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'cancel',
        headerName: 'Cancelar',
        headerAlign: 'center',
        align: 'center',
        minWidth: 100,
        renderCell: (param) => {
          if (param.value) {
            if (param.value.canceledProjectExport) {
              return (<>
                <Button>
                  <Tooltip
                    title={`Extração cancelada`}
                    arrow
                    placement="right"
                    role='dialog'
                  >
                    <Grid container alignItems="center" justifyContent="center">
                      <Block color='primary' onClick={() => {
                        setCanceledProjectExportInfo(param.value.canceledProjectExport as ICanceledProjectExport)
                        setOpenCanceledExportInfo(true)
                      }} />
                    </Grid>
                  </Tooltip>
                </Button>
              </>
              )
            } else {
              return (<>
                <Button>
                  <Tooltip
                    title="Cancelar extração"
                    arrow
                    placement="right"
                    role='dialog'
                  >
                    <Grid container alignItems="center" justifyContent="center">
                      <DeleteForever
                        color='primary'
                        onClick={(e) => {
                          setOpenConfirmationScreen(param.value.projectExport as IProjectExport)
                        }}
                      />
                    </Grid>
                  </Tooltip>
                </Button>
              </>
              )
            }
          }
        }
      },
      {
        field: 'name',
        headerName: 'Nome',
        minWidth: 145,
        flex: 1,
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'company',
        headerName: 'Cliente',
        minWidth: 145,
        flex: 0.7,
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'createdBy',
        headerName: 'Usuário',
        minWidth: 140,
        flex: 0.7,
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'totalExports',
        headerName: 'Qtd. de registros',
        minWidth: 125,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => {
          if (params.value?.canceledProjectExport) {
            return '0'
          } else {
            return params.value?.projectExport.total.toLocaleString('pt-BR')
          }
        }
      },
      {
        field: 'createdAt',
        headerName: 'Data de criação',
        minWidth: 125,
        type: 'date',
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => {
          return format(new Date(params.value as string), 'dd/MM/yyyy - HH:mm ')
        }
      },
      {
        field: 'type',
        headerAlign: 'center',
        align: 'center',
        headerName: 'Tipo',
        minWidth: 100,
        renderCell: (params) => (
          <>
            {
              params.value === 'PROJECT' ? (
                <Tooltip
                  title="Projeto"
                  arrow
                  placement="right"
                  role='dialog'
                >
                  <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <Grid item>
                      P
                    </Grid>
                    <Grid item>
                      <BubbleChartIcon />
                    </Grid>
                  </Grid>
                </Tooltip>
              ) :
                <Tooltip
                  title="Enriquecimento"
                  arrow
                  placement="right"
                  role='dialog'
                >
                  <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <Grid item>
                      E
                    </Grid>
                    <Grid item>
                      <CloudUploadIcon />
                    </Grid>
                  </Grid>
                </Tooltip>
            }
          </>
        )
      },
      {
        field: 'download',
        headerAlign: 'center',
        align: 'center',
        headerName: 'Download',
        minWidth: 100,
        renderCell: (params) => {
          if (icpNotConfigured) {

            return (
              <Tooltip
                title="Selecione um modelo para a coluna ICP antes de continuar!"
                arrow
                placement="right"
              >
                <Button disabled>Download</Button>
              </Tooltip>
              
            );
          }
          return (
            <>
              {
                !params.value.canceledProjectExport ?
                
                user.userType === "SELLER" || user.userType === 'OPERATOR' ?

                    params.value.projectExport.createdBy === user.id ?
                      params.value ? (
                        <StatusCell key={params.value.projectExport.id} {...params.value} />
                      ) : null
                      : <LockIcon key={params.value.projectExport.id} color='primary' />
                    : params.value ? (
                      <StatusCell key={params.value.projectExport.id} {...params.value} />
                    ) : null
                    
                  : <Tooltip
                      title="Extração cancelada"
                      arrow
                      placement="right"
                      role='dialog'
                    >
                      <Download
                        key={params.value.projectExport.id}
                        style={{
                          filter: 'grayscale(100%)',
                          opacity: 0.3
                        }}
                      />
                    </Tooltip>
              }
            </>
          );
        }
      },
      {
        field: 'googleADS',
        headerAlign: 'center',
        align: 'center',
        headerName: 'Google ADS',
        minWidth: 100,
        renderCell: (params) => {
          return (
            !params.value.canceledProjectExport ?
              user.userType === "SELLER" || user.userType === 'OPERATOR' ?
                params.value.projectExport.createdBy === user.id ?
                  params.value ?
                    <Button
                      key={params.value.projectExport.id}
                      sx={{
                        cursor: params.value.fileExists === true ? 'pointer' : 'auto'
                      }}
                      onClick={() => {
                        if (params.value.fileExists === true) {
                          downloadGoogleADS({
                            projectExportId: params.value?.projectExport.id ?? ''
                          })
                        }
                      }}>
                      <img
                        key={params.value.projectExport.id}
                        width={30}
                        src={GoogleAdsIcon}
                        alt="Google ADS Download"
                        style={{
                          filter: (params.value.fileExists === true) ? 'none' : 'grayscale(100%)',
                          opacity: (params.value.fileExists === true) ? 1 : 0.7
                        }}
                      />
                    </Button>
                    : null
                  : <LockIcon key={params.value.projectExport.id} color='primary' />
                : params.value ?
                  <Button
                    key={params.value.projectExport.id}
                    sx={{
                      cursor: params.value.fileExists === true ? 'pointer' : 'auto'
                    }}
                    onClick={() => {
                      if (params.value.fileExists === true) {
                        downloadGoogleADS({
                          projectExportId: params.value?.projectExport.id ?? ''
                        })
                      }
                    }}>
                    <img
                      key={params.value.projectExport.id}
                      width={30}
                      src={GoogleAdsIcon}
                      alt="Google ADS Download"
                      style={{
                        filter: params.value.fileExists === true ? 'none' : 'grayscale(100%)',
                        opacity: params.value.fileExists === true ? 1 : 0.7
                      }}
                    />
                  </Button>
                  : null
              : <img
                key={params.value.projectExport.id}
                width={30}
                src={GoogleAdsIcon}
                alt="Google ADS Download"
                style={{
                  filter: 'grayscale(100%)',
                  opacity: 0.7
                }}
              />
          )
        }
      },
      {
        field: 'facebookADS',
        headerAlign: 'center',
        align: 'center',
        headerName: 'Facebook ADS',
        minWidth: 115,
        renderCell: (params) => (
          !params.value.canceledProjectExport ?
            user.userType === "SELLER" || user.userType === 'OPERATOR' ?
              params.value.projectExport.createdBy === user.id ?
                params.value ?
                  <Button
                    key={params.value.projectExport.id}
                    sx={{
                      cursor: params.value.fileExists === true ? 'pointer' : 'auto'
                    }}
                    onClick={() => {
                      if (params.value.fileExists === true) {
                        downloadFacebookAds({
                          projectExportId: params.value?.projectExport.id ?? ''
                        })
                      }
                    }}
                  >
                    <img
                      key={params.value.projectExport.id}
                      width={40}
                      src={FacebookAdsIcon}
                      alt="Facebook ADS Download"
                      style={{
                        filter: params.value.fileExists === true ? 'none' : 'grayscale(100%)',
                        opacity: params.value.fileExists === true ? 1 : 0.7
                      }}
                    />
                  </Button>
                  : null
                : <LockIcon key={params.value.projectExport.id} color='primary' />
              : params.value ?
                <Button
                  key={params.value.projectExport.id}
                  sx={{
                    cursor: params.value.fileExists === true ? 'pointer' : 'auto'
                  }}
                  onClick={() => {
                    if (params.value.fileExists === true) {
                      downloadFacebookAds({
                        projectExportId: params.value?.projectExport.id ?? ''
                      })
                    }
                  }}
                >
                  <img
                    key={params.value.projectExport.id}
                    width={40}
                    src={FacebookAdsIcon}
                    alt="Facebook ADS Download"
                    style={{
                      filter: params.value.fileExists === true ? 'none' : 'grayscale(100%)',
                      opacity: params.value.fileExists === true ? 1 : 0.7
                    }}
                  />
                </Button>
                : null
            : <img
              key={params.value.projectExport.id}
              width={40}
              src={FacebookAdsIcon}
              alt="Facebook ADS Download"
              style={{
                filter: 'grayscale(100%)',
                opacity: 0.7
              }}
            />
        )
      },
    ]
  }, [downloadFacebookAds, downloadGoogleADS, user.id, user.userType, icpNotConfigured])

  useEffect(() => {
    setProjectExportList()
  }, [setProjectExportList, projectExport.page])

  return (<>
    <Dialog
      onClose={() => setOpenConfirmationScreen(null)}
      open={openConfirmationScreen?.id ? true : false}
      fullWidth
      maxWidth={'sm'}
      disableEscapeKeyDown
    >
      <Grid container alignItems="center">
        <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
          <DialogTitle
            color="primary"
            fontWeight="bold"
            textTransform="uppercase"
            sx={{
              flexGrow: 1,
              textAlign: 'center'
            }}
          >
            Informações
          </DialogTitle>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <DialogContent>
        <Typography><strong>Nome da extração: </strong>{openConfirmationScreen?.name ?? ''}</Typography>
        <Typography><strong>Criada por: </strong>{openConfirmationScreen?.userName ?? ''}</Typography>
        <Typography><strong>Cliente: </strong>{openConfirmationScreen?.razaoSocial ?? ''}</Typography>
        <Typography><strong>Quantidade de registros: </strong>{openConfirmationScreen?.total.toLocaleString('pt-Br') ?? ''}</Typography>
        <Grid my={2}>
          <Divider />
        </Grid>
        <Grid container display={'flex'}>
          <Grid item xs={12}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            mb={2}
          >
            <Typography
              variant='h6'
              color="primary"
              fontWeight={"bold"}
              textTransform={"uppercase"}
              sx={{
                flexGrow: 1,
                textAlign: 'center'
              }}
            >
              Confirma o cancelamento desta extração?
            </Typography>
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={4}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => setOpenConfirmationScreen(null)}
                sx={{
                  textTransform: 'uppercase'
                }}
              >
                Não
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  cancelProjectExport({
                    resourceId: openConfirmationScreen?.id ?? ''
                  })
                  setOpenConfirmationScreen(null)
                }}
                sx={{
                  textTransform: 'uppercase'
                }}
              >
                Sim
              </Button>
            </Grid>
          </Grid>

        </Grid>
      </DialogContent>
    </Dialog>
    <Dialog
      onClose={() => {
        setCanceledProjectExportInfo(null)
        setOpenCanceledExportInfo(false)
      }}
      open={openCanceledExportInfo}
      fullWidth
      maxWidth={'sm'}
    >
      <Grid container display={'flex'}>
        <Grid item xs={12}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <DialogTitle
            color="primary"
            fontWeight={"bold"}
            textTransform={"uppercase"}
            sx={{
              flexGrow: 1,
              textAlign: 'center'
            }}
          >
            Informações da Extração Cancelada
          </DialogTitle>
          <IconButton
            onClick={() => {
              setCanceledProjectExportInfo(null)
              setOpenCanceledExportInfo(false)
            }}
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <DialogContent>
        <Typography><strong>Cancelada por: </strong>{canceledProjectExportInfo?.userName ?? ''}</Typography>
        <Typography><strong>Cancelada em: </strong>{canceledProjectExportInfo?.createdAt ? format(new Date(canceledProjectExportInfo.createdAt), 'dd/MM/yyyy - HH:mm ') : ''}</Typography>
        <Typography><strong>IP: </strong>{canceledProjectExportInfo?.ip ?? ''}</Typography>
        <Typography><strong>Quantidade de registros: </strong>{canceledProjectExportInfo?.idList.length.toLocaleString('pt-Br') ?? ''}</Typography>
        <Grid my={2}>
          <Divider />
        </Grid>
        <Grid container display={'flex'}>
          <Grid item xs={12}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            mb={2}
          >
            <Typography
              variant='h6'
              color="primary"
              fontWeight={"bold"}
              textTransform={"uppercase"}
              sx={{
                flexGrow: 1,
                textAlign: 'center'
              }}
            >
              CNPJs presentes na extração:
            </Typography>
          </Grid>
        </Grid>
        <Grid container height={200}>
        <AutoSizer>
            {(data: any) => (
              <FixedSizeList
                height={200}
                style={{
                  maxHeight: 400
                }}
                width={data.width}
                itemSize={50}
                itemCount={(canceledProjectExportInfo?.idList.length ?? 0)}
                overscanCount={15}
              >
                {({ index, style }) => {
                  const item = canceledProjectExportInfo?.idList[index]

                  if (item === undefined) return <></>

                  return (
                    <ListItem
                      style={style}
                      key={index}
                      sx={{
                        background: (index % 2 !== 0) ? '#f6f6f6' : ''
                      }}
                    >
                      <ListItemText primary={formatCnpj(item ?? '')} />
                    </ListItem>
                  )
                }}
              </FixedSizeList>
            )}
          </AutoSizer>
        </Grid>
      </DialogContent>
    </Dialog>
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          defaultValue={null}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filtros"
            />
          )}
          onChange={(event, newValue) => {
            resetTableFilters()
            if (newValue) {
              setSelectedOption(newValue.id)
            } else {
              setSelectedOption(null)
              resetTableFilters()
            }
          }}
        />
      </Grid>
      <Grid item xs={8}>
        {
          // Name
          selectedOption === 1
          && <TextField
            onChange={debounce((e) => {
              setTableFilterName({
                name: e.target.value
              })
            }, 1000)}
            fullWidth
            id="search"
            label="Digite o nome da extração"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <Search />
              )
            }}
          />
        }
        {
          // Company Id
          company?.master || company?.resale ?
            selectedOption === 2 && <CompaniesVirtualizedList />
            : null
        }
        {
          // Created By
          company?.master || company?.resale || (user.userType === 'ADMIN' || user.userType === 'OWNER') ?
            selectedOption === 3 && <UsersVirtualizedList />
            : null
        }
        {
          // Created At
          selectedOption === 4
          && <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={ptBR}
                >
                  <DatePicker
                    disableFuture
                    value={tableFilters.startDate ? tableFilters.startDate : null}
                    onChange={debounce((newValue: Date | null, context) => {
                      if (context.validationError !== 'invalidDate') {
                        if (newValue) {
                          setTableFilterStartDate({
                            startDate: newValue
                          })
                        } else {
                          setTableFilterStartDate({
                            startDate: undefined
                          })
                        }
                      }
                    }, 1000)}
                    label="A partir de"
                    slotProps={{
                      day: {
                        sx: {
                          "&.MuiPickersDay-root.Mui-selected": {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            '&:hover': {
                              backgroundColor: darkenColor(theme.palette.primary.main, 10)
                            }
                          }
                        }
                      },
                      textField: { fullWidth: true }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={ptBR}
                >
                  <DatePicker
                    disableFuture
                    value={tableFilters.endDate ? tableFilters.endDate : null}
                    onChange={debounce((newValue: Date | null, context) => {
                      if (context.validationError !== 'invalidDate') {
                        if (newValue) {
                          setTableFilterEndDate({
                            endDate: newValue
                          })
                        } else {
                          setTableFilterEndDate({
                            endDate: undefined
                          })
                        }
                      }
                    }, 1000)}
                    label="Até"
                    slotProps={{
                      day: {
                        sx: {
                          "&.MuiPickersDay-root.Mui-selected": {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            '&:hover': {
                              backgroundColor: darkenColor(theme.palette.primary.main, 10)
                            }
                          }
                        }
                      },
                      textField: { fullWidth: true }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </>
        }
        {
          // Type
          selectedOption === 5
          && <Autocomplete
            options={[
              {
                label: 'Projeto',
                id: 'PROJECT'
              },
              {
                label: 'Enriquecimento',
                id: 'ENRICHMENT'
              }
            ]}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            defaultValue={null}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tipo de Extração"
              />
            )}
            onChange={(event, newValue) => {
              if (newValue) {
                setTableFilterType({
                  type: newValue.id
                })
              } else {
                setTableFilterType({
                  type: ''
                })
              }
            }}
          />
        }
      </Grid>
      <Grid item xs={12}>
        <Box sx={{
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: theme.palette.primary.main,
            color: company?.resale === true || company?.resaleId
              ? theme.palette.primary.contrastText
              : '#FFF'
          },
          '& .MuiDataGrid-cell': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 0
          },
          minWidth: '100%'
        }}>
          <DataGrid
            showCellVerticalBorder
            disableColumnFilter
            disableColumnMenu
            keepNonExistentRowsSelected
            rowSelection={false}
            autoHeight={true}
            localeText={dataGridTexts}
            pagination={true}
            pageSizeOptions={[10]}
            paginationModel={{
              page: projectExport.page - 1,
              pageSize: 10
            }}
            onPaginationModelChange={(params) => {
              setPage({
                page: params.page + 1
              })
            }}
            paginationMode='server'
            loading={projectExport.loading}
            rowCount={projectExport.total}
            rows={projectExport.exportList.map((item) => {
              return {
                id: item.projectExport.id,
                cancel: item,
                name: item.projectExport.name,
                company: nameAbbreviation(item.projectExport.razaoSocial.toUpperCase()),
                createdBy: nameAbbreviation(item.projectExport.userName.toUpperCase()),
                totalExports: item,
                createdAt: new Date(item.projectExport.createdAt),
                type: item.projectExport.type,
                googleADS: item,
                facebookADS: item,
                download: item
              }
            })}
            columns={columns.filter((column) => {
              if ((company?.master === false && company?.resale === false) || user.userType === "OPERATOR") {
                return (column.field !== 'company' && column.field !== 'createdBy' && column.field !== 'cancel')
              }
              if (company?.master === false) {
                return column.field !== 'cancel'
              }

              if (user.userType !== 'OWNER') {
                return column.field !== 'cancel'
              }

              return column
            })}
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              minWidth: '100%'
            }}
          />
        </Box>
      </Grid>
    </Grid>
  </>)
}